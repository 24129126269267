import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  share,
  shareReplay,
} from 'rxjs/operators';
import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';

import { BaseModel } from '../models/base.model';
import { MyShoppingLotModel } from '../models/my-shopping-lot.model';
import { MyShoppingPaymentModel } from '../models/my-shopping-payment.model';
import { PaginationResult } from '../models/pagination-result.model';
import { HubConnectionService } from './hub-connection.service';


@Injectable()
export class MyShoppingService
{
    constructor(
      private http: HttpClient,
      private hubConnectionService: HubConnectionService
    ) {}
    
    queryMyShoppingsPayment(
      options?: LoadOptionsParamsLike
    ): Observable<PaginationResult<MyShoppingPaymentModel>> {
      const params = new LoadOptionsParams(options).toHttpParams();
      return this.http
        .get<PaginationResult<MyShoppingPaymentModel>>(`~api/Shopping/MyShoppings`, {
          params,
        })
        .pipe(
          map((res) => {
            res.data = BaseModel.createArrayOrDefault(
              MyShoppingPaymentModel,
              res.data
            );

            return res;
          })
        );
    }
    
    queryMyShoppingsLot(
      options?: LoadOptionsParamsLike
    ): Observable<PaginationResult<MyShoppingLotModel>> {
      const params = new LoadOptionsParams(options).toHttpParams();

      return this.http
      .get<PaginationResult<MyShoppingLotModel>>(`~api/Shopping/MyShoppingsLotes`, {
        params,
      })
      .pipe(
        map((res) => {
        res.data = BaseModel.createArrayOrDefault(
          MyShoppingLotModel,
          res.data
        );
        
        return res;
        })
      );
    }
}