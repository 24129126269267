import { AuctionLotStatusEnum } from './auction-lot-status.enum';
import { BaseModel } from './base.model';
import { BreedModelLike } from './breed.model';

export interface InstallmentModelLike {
  number?: number;
  price?: number;
}

export interface AuctionLotRunningModelLike {
  id?: string;
  auctionId?: string;
  name?: string;
  resume?: string;
  description?: string;
  breed?: BreedModelLike;
  gender?: string;
  status?: AuctionLotStatusEnum;
  incrementMin?: number[];
  bids?: number;
  parcela?: InstallmentModelLike;
  imageUrl?: string;
  dateEnd?: Date;
  birthDay?: Date;
}

export class AuctionLotRunningModel
  extends BaseModel<AuctionLotRunningModelLike, AuctionLotRunningModel>
  implements AuctionLotRunningModelLike
{
  id?: string;
  auctionId?: string;
  name?: string;
  resume?: string;
  description?: string;
  breed?: BreedModelLike;
  gender?: string;
  status?: AuctionLotStatusEnum;
  incrementMin?: number[];
  bids?: number;
  parcela?: InstallmentModelLike;
  imageUrl?: string;
  dateEnd?: Date;
  birthDay?: Date;

  get isSold() {
    return this.status === AuctionLotStatusEnum.Sold;
  }

  apply(item: Partial<AuctionLotRunningModelLike>) {
    this.id = item?.id;
    this.auctionId = item?.auctionId;
    this.name = item?.name;
    this.resume = item?.resume;
    this.description = item?.description;
    this.breed = item?.breed;
    this.gender = item?.gender;
    this.status = item?.status;
    this.incrementMin = item?.incrementMin;
    this.bids = item?.bids;
    this.parcela = item?.parcela;
    this.imageUrl = item?.imageUrl;
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.birthDay = this.parseDateTime(item?.birthDay);
  }
}
