import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  shareReplay,
} from 'rxjs/operators';

@Injectable()
export class GlobalLoadingService {
  private _loadingCount = 0;

  private loadingSubject: Subject<boolean>;

  readonly loadingChanges: Observable<boolean>;

  constructor() {
    this.loadingSubject = new BehaviorSubject(this.isLoading());
    this.loadingChanges = this.loadingSubject.pipe(
      distinctUntilChanged(),
      debounceTime(0),
      shareReplay(1)
    );
  }

  isLoading() {
    return this._loadingCount > 0;
  }

  showLoading() {
    ++this._loadingCount;
    this.loadingSubject.next(this.isLoading());
  }

  hideLoading() {
    if (this._loadingCount > 0) {
      --this._loadingCount;
    }
    this.loadingSubject.next(this.isLoading());
  }
}
