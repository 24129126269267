import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';
import { PaginationResult } from '../models/pagination-result.model';
import Query from 'devextreme/data/query';

export class LoadOptionsUtils {
  static loadData<T>(
    data: T[],
    options?: LoadOptionsParamsLike
  ): PaginationResult<T> {
    const loadOptions = new LoadOptionsParams(options);
    let query = Query(data);

    const filters = loadOptions.resolveFilters();
    if (filters?.length) {
      query = query.filter(filters);
    }

    const sorters = loadOptions.resolveSorters();
    if (sorters?.length) {
      let sorter = sorters[0];
      query = query.sortBy(sorter.selector, sorter.desc ?? false);
      for (let i = 1; i < sorters.length; ++i) {
        sorter = sorters[i];
        query = query.thenBy(sorter.selector, sorter.desc ?? false);
      }
    }

    data = query.toArray();
    const totalCount = data.length;
    const skip = loadOptions.getSkip() ?? 0;
    const take = loadOptions.getTake() ?? totalCount;
    data = data.slice(skip, skip + take);

    return { totalCount, data };
  }
}
