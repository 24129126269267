import _ from 'lodash';
import { BaseModel } from './base.model';

export interface InstallmentMinMaxLike {
  incrementMin?: number;
  incrementMax?: number;
}

export interface AuctionRegisterModelLike {
  id?: string;
  name?: string;
  description?: string;
  dateStart?: Date;
  dateEnd?: Date;
  parcelaLimit?: number;
  parcelaAVista?: number;
  parcelaUntil30?: number;
  parcelaUntil60?: number;
  parcelaRemaining?: number;
  imageBannerInProgressId?: string;
  imageHeaderAuctionUrl?: string;

  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;

  imageHeaderAuctionId?: string;
  imageBannerInProgressUrl?: string;

  status?: string;
  parcelas?: InstallmentMinMaxLike[];
  valueStart?: number;
}

export class AuctionRegisterModel
  extends BaseModel<AuctionRegisterModelLike, AuctionRegisterModel>
  implements AuctionRegisterModelLike
{
  id?: string;
  name?: string;
  description?: string;
  dateStart?: Date;
  dateEnd?: Date;
  parcelaLimit?: number;
  parcelaAVista?: number;
  parcelaUntil30?: number;
  parcelaUntil60?: number;
  parcelaRemaining?: number;
  imageBannerInProgressId?: string;
  imageHeaderAuctionUrl?: string;

  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;

  imageHeaderAuctionId?: string;
  imageBannerInProgressUrl?: string;

  status?: string;
  parcelas?: InstallmentMinMaxLike[];
  valueStart?: number;

  apply(item: Partial<AuctionRegisterModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.description = item?.description;
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.parcelaLimit = item?.parcelaLimit;
    this.parcelaAVista = item?.parcelaAVista;
    this.parcelaUntil30 = item?.parcelaUntil30;
    this.parcelaUntil60 = item?.parcelaUntil60;
    this.parcelaRemaining = item?.parcelaRemaining;
    this.imageBannerInProgressId = item?.imageBannerInProgressId;
    this.imageHeaderAuctionUrl = item?.imageHeaderAuctionUrl;

    this.imageBannerLastMinuteId = item?.imageBannerLastMinuteId;
    this.imageBannerLastMinuteUrl = item?.imageBannerLastMinuteUrl;

    this.imageHeaderAuctionId = item?.imageHeaderAuctionId;
    this.imageBannerInProgressUrl = item?.imageBannerInProgressUrl;

    this.status = item?.status;
    this.parcelas = _.cloneDeep(item?.parcelas ?? []);
    this.valueStart = item?.valueStart;
  }
}
