import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadPanelComponent } from './load-panel.component';
import { DxPopupModule } from 'devextreme-angular/ui/popup';
import { DxLoadIndicatorModule } from 'devextreme-angular/ui/load-indicator';

@NgModule({
  imports: [CommonModule, DxLoadIndicatorModule, DxPopupModule],
  declarations: [LoadPanelComponent],
  exports: [LoadPanelComponent],
})
export class LoadPanelModule {}
