import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { RxLocalStorage } from '@rxjs-storage/core';
import { fromFetch } from 'rxjs/fetch';
import { mergeMap } from 'rxjs/operators';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
import { DefaultLayoutModule } from './layouts/default-layout/default-layout.module';
import { LoadPanelModule } from './shared/components/load-panel/load-panel.module';
import { ApiInterceptor } from './shared/interceptors/api.interceptor';
import { AppConfigLike } from './shared/interfaces/config.interface';
import { AddressService } from './shared/services/address.service';
import { AppInfoService } from './shared/services/app-info.service';
import { AuctionLotService } from './shared/services/auction-lot.service';
import { MyShoppingService } from './shared/services/my-shopping.service';
import { AuctionStatusService } from './shared/services/auction-status.service';
import { AuctionService } from './shared/services/auction.service';
import { AuthService } from './shared/services/auth.service';
import { BreedService } from './shared/services/breed.service';
import { CacheFactoryService } from './shared/services/cache-factory.service';
import { CardsService } from './shared/services/cards.service';
import { CountdownService } from './shared/services/countdown.service';
import { EventBusService } from './shared/services/event-bus.service';
import { GlobalLoadingService } from './shared/services/global-loading.service';
import { HorseGenderService } from './shared/services/horse-gender.service';
import { HubConnectionService } from './shared/services/hub-connection.service';
import { ProfileService } from './shared/services/profile.service';
import { ToastService } from './shared/services/toast.service';
import { UploadService } from './shared/services/upload.service';
import { CarouselModule } from 'ngx-bootstrap/carousel';

let appConfig: AppConfigLike;

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthLayoutModule,
    DefaultLayoutModule,
    LoadPanelModule,
    TranslateModule.forRoot({
      useDefaultLang: false,
      loader: {
        provide: TranslateLoader,
        useFactory: () => {
          class CustomTranslateLoader extends TranslateLoader {
            getTranslation(lang: string): any {
              return fromFetch(`locale/${lang}.json`).pipe(
                mergeMap((response) => response.json())
              );
            }
          }

          return new CustomTranslateLoader();
        },
      },
    }),
    CarouselModule.forRoot(),
  ],
  providers: [
    {
      provide: RxLocalStorage,
      useFactory: () => new RxLocalStorage('horses-leilao'),
    },
    {
      provide: AppInfoService,
      useFactory: () => new AppInfoService(appConfig),
    },
    AuthService,
    ProfileService,
    AuctionService,
    AuctionLotService,
    MyShoppingService,
    AuctionStatusService,
    BreedService,
    HorseGenderService,
    UploadService,
    AddressService,
    CardsService,
    HubConnectionService,
    GlobalLoadingService,
    ToastService,
    EventBusService,
    CacheFactoryService,
    CountdownService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translateService: TranslateService) => {
        return async () => {
          const appConfigPromise = fetch('config.json').then((x) => x.json());
          await translateService.use('pt').toPromise();
          appConfig = await appConfigPromise;
        };
      },
      multi: true,
      deps: [TranslateService],
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
