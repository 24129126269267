import { NgModule } from '@angular/core';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS } from 'ng-lazyload-image';
import { LazyLoadImageHooks } from './lazyload-image-hooks';

@NgModule({
  imports: [LazyLoadImageModule],
  exports: [LazyLoadImageModule],
  providers: [{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: LazyLoadImageHooks }],
})
export class CustomLazyLoadImageModule {}
