import { AfterViewInit } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DxPopupComponent } from 'devextreme-angular/ui/popup';
import { animationFrameScheduler } from 'rxjs';
import { delay, observeOn } from 'rxjs/operators';

@Component({
  selector: 'app-load-panel',
  templateUrl: './load-panel.component.html',
  styleUrls: ['./load-panel.component.scss'],
})
export class LoadPanelComponent
  implements OnChanges, AfterViewInit, AfterViewChecked {
  @ViewChild(DxPopupComponent, { static: false })
  popup: DxPopupComponent;

  @Input()
  container: string | Element;

  @Input()
  visible: boolean;
  @Output()
  visibleChange = new EventEmitter<boolean>();

  @Input()
  shading = true;
  @Input()
  showLoadIndicator = true;
  @Input()
  message: string;

  get hasMessage() {
    return !!this.message;
  }

  private renderWhenSubject = new EventEmitter<boolean>(true);
  renderWhen = this.renderWhenSubject.pipe(
    delay(100),
    observeOn(animationFrameScheduler)
  );

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.showLoadIndicator || changes.message) &&
      this.popup &&
      this.popup.instance
    ) {
      this.popup.instance.repaint();
    }
  }

  ngAfterViewInit() {}

  ngAfterViewChecked() {
    if (this.renderWhenSubject) {
      this.renderWhenSubject.emit(true);
      this.renderWhenSubject.complete();
      this.renderWhenSubject = undefined!;
    }
  }
}
