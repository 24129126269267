import { BaseModel } from './base.model';

export interface AuctionRunningModelLike {
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  imageBannerInProgressId?: string;
  imageBannerInProgressUrl?: string;
  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;
}

export class AuctionRunningModel
  extends BaseModel<AuctionRunningModelLike, AuctionRunningModel>
  implements AuctionRunningModelLike
{
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  imageBannerInProgressId?: string;
  imageBannerInProgressUrl?: string;
  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;

  apply(item: Partial<AuctionRunningModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.created = this.parseDateTime(item?.created);
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.imageBannerInProgressId = item?.imageBannerInProgressId;
    this.imageBannerInProgressUrl = item?.imageBannerInProgressUrl;
    this.imageBannerLastMinuteId = item?.imageBannerLastMinuteId;
    this.imageBannerLastMinuteUrl = item?.imageBannerLastMinuteUrl;
  }
}
