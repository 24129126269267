import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  share,
  shareReplay,
} from 'rxjs/operators';
import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';
import {
  AuctionRegisterModel,
  AuctionRegisterModelLike,
} from '../models/auction-register.model';
import { AuctionEventModel } from '../models/auction-event.model';
import { AuctionFinishedModel } from '../models/auction-finished.model';
import { AuctionHighlightModel } from '../models/auction-highlight.model';
import { AuctionRunningDetailModel } from '../models/auction-running-detail.model';
import { AuctionRunningModel } from '../models/auction-running.model';
import { AuctionModel } from '../models/auction.model';
import { BaseModel } from '../models/base.model';
import { PaginationResult } from '../models/pagination-result.model';
import { CacheFactoryService, ICacheService } from './cache-factory.service';
import { HubConnectionService } from './hub-connection.service';

@Injectable()
export class AuctionService {
  private cacheService: ICacheService;

  private _auctionObservables = new Map<
    string,
    Observable<AuctionEventModel>
  >();
  private _auctionThrowsCountObservables = new Map<
    string,
    Observable<number>
  >();
  private _auctionAvailableLotsCountObservables = new Map<
    string,
    Observable<number>
  >();
  private _auctionFinishAtObservables = new Map<string, Observable<Date>>();

  constructor(
    private http: HttpClient,
    private hubConnectionService: HubConnectionService,
    cacheFactory: CacheFactoryService
  ) {
    this.cacheService = cacheFactory.getOrCreate('auctions');
  }

  getHighlightAuction(): Observable<AuctionHighlightModel> {
    return this.http
      .get<AuctionHighlightModel>(`~api/Auction/AuctionHighlight`)
      .pipe(
        map((data) => {
          const model = BaseModel.createOrDefault(AuctionHighlightModel, data);
          return model;
        })
      );
  }

  listAuctionsFinishing(): Observable<AuctionRunningModel[]> {
    return this.http
      .get<PaginationResult<AuctionRunningModel>>(
        `~api/Auction/AuctionsLastMinutes`
      )
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionRunningModel,
            res.data
          );
          return res.data;
        })
      );
  }

  listAuctionsNext(): Observable<AuctionRunningModel[]> {
    return this.http
      .get<PaginationResult<AuctionRunningModel>>(`~api/Auction/AuctionsNext`)
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionRunningModel,
            res.data
          );
          return res.data;
        })
      );
  }

  queryAuctionsRunning(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionRunningModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionRunningModel>>(
        `~api/Auction/AuctionsRunning`,
        {
          params,
        }
      )
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionRunningModel,
            res.data
          );
          return res;
        })
      );
  }

  queryLastAuctions(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionFinishedModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionFinishedModel>>(
        `~api/Auction/LastAuctions`,
        { params }
      )
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionFinishedModel,
            res.data
          );
          return res;
        })
      );
  }

  queryMyAuctions(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionModel>>(`~api/Auction/AuctionsList`, {
        params,
      })
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(AuctionModel, res.data);
          return res;
        })
      );
  }

  getAuctionRunningDetailById(
    auctionId: string
  ): Observable<AuctionRunningDetailModel> {
    return this.http
      .get<AuctionRunningDetailModel>(`~api/Auction/AuctionDetail`, {
        params: { Id: auctionId },
      })
      .pipe(
        map((res) => {
          res = BaseModel.createOrDefault(AuctionRunningDetailModel, res);
          return res;
        })
      );
  }

  getAuctionForChangeById(auctionId: string): Observable<AuctionRegisterModel> {
    return this.http
      .get<AuctionRegisterModel>(`~api/Auction/GetAuctionForChange`, {
        params: { Id: auctionId },
      })
      .pipe(
        map((res) => {
          res = BaseModel.createOrDefault(AuctionRegisterModel, res);
          return res;
        })
      );
  }

  observeAuction(auctionId: string): Observable<AuctionEventModel> {
    return new Observable<AuctionEventModel>((subscriber) => {
      let observable: Observable<AuctionEventModel>;

      if (!this._auctionObservables.has(auctionId)) {
        observable = this.hubConnectionService
          .invoke('CreateConnectAuctionDetail', auctionId)
          .pipe(
            shareReplay(1),
            mergeMap(() => {
              return this.hubConnectionService.on('OnAuctionChange').pipe(
                filter((data) => {
                  return data?.auctionId === auctionId;
                })
              );
            }),
            filter((x) => !!x),
            map((model) => {
              return BaseModel.createOrDefault(AuctionEventModel, model);
            }),
            finalize(() => {
              // this._auctionFinishAtObservables.delete(auctionId);
            }),
            share()
          );
        this._auctionObservables.set(auctionId, observable);
      } else {
        observable = this._auctionObservables.get(auctionId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionThrowsCount(auctionId: string): Observable<number> {
    return new Observable((subscriber) => {
      let observable: Observable<number>;

      if (!this._auctionThrowsCountObservables.has(auctionId)) {
        observable = this.observeAuction(auctionId).pipe(
          map((x) => x?.bids),
          filter((x) => !!x),
          distinctUntilChanged(),
          finalize(() => {
            this._auctionThrowsCountObservables.delete(auctionId);
          }),
          share()
        );
        this._auctionThrowsCountObservables.set(auctionId, observable);
      } else {
        observable = this._auctionThrowsCountObservables.get(auctionId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionAvailableLotsCount(auctionId: string): Observable<number> {
    return new Observable((subscriber) => {
      let observable: Observable<number>;

      if (!this._auctionAvailableLotsCountObservables.has(auctionId)) {
        observable = this.observeAuction(auctionId).pipe(
          map((x) => x?.numberLotes),
          filter((x) => !!x),
          distinctUntilChanged(),
          finalize(() => {
            this._auctionAvailableLotsCountObservables.delete(auctionId);
          }),
          share()
        );
        this._auctionAvailableLotsCountObservables.set(auctionId, observable);
      } else {
        observable = this._auctionAvailableLotsCountObservables.get(auctionId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionFinishAt(auctionId: string): Observable<Date> {
    return new Observable((subscriber) => {
      let observable: Observable<Date>;

      if (!this._auctionFinishAtObservables.has(auctionId)) {
        observable = this.observeAuction(auctionId).pipe(
          map((x) => x?.dateEnd),
          filter((x) => !!x),
          distinctUntilChanged(
            (x, y) => x === y,
            (x) => x?.getTime()
          ),
          finalize(() => {
            this._auctionFinishAtObservables.delete(auctionId);
          }),
          share()
        );
        this._auctionFinishAtObservables.set(auctionId, observable);
      } else {
        observable = this._auctionFinishAtObservables.get(auctionId);
      }

      return observable.subscribe(subscriber);
    });
  }

  createAuction(model?: AuctionRegisterModelLike): Observable<any> {
    return this.http.post<any>(`~api/Auction/Add`, model);
  }

  changeAuction(model?: AuctionRegisterModelLike): Observable<any> {
    return this.http.put<any>(`~api/Auction/Change`, model);
  }
}
