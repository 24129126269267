import { BaseModel } from './base.model';

export interface AuctionHighlightModelLike {
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  throwsCount?: number;
  lotsAvailable?: number;
  imageHeaderAuctionId?: string;
  imageHeaderAuctionUrl?: string;
}

export class AuctionHighlightModel
  extends BaseModel<AuctionHighlightModelLike, AuctionHighlightModel>
  implements AuctionHighlightModelLike
{
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  throwsCount?: number;
  lotsAvailable?: number;
  imageHeaderAuctionId?: string;
  imageHeaderAuctionUrl?: string;

  apply(item: Partial<AuctionHighlightModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.created = this.parseDateTime(item?.created);
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.throwsCount = item?.throwsCount;
    this.lotsAvailable = item?.lotsAvailable;
    this.imageHeaderAuctionId = item?.imageHeaderAuctionId;
    this.imageHeaderAuctionUrl = item?.imageHeaderAuctionUrl;
  }
}
