import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuctionLotRunningDetailModel } from '../models/auction-lot-running-detail.model';
import { AuctionLotService } from '../services/auction-lot.service';

@Injectable()
export class AuctionLotDetailRunningResolver
  implements Resolve<AuctionLotRunningDetailModel>
{
  constructor(private auctionLotService: AuctionLotService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AuctionLotRunningDetailModel> {
    return this.auctionLotService.getLotRunningDetail(
      route.params.auctionLotId
    );
  }
}
