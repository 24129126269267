<dx-popup
  *ngIf="renderWhen | async"
  class="custom-load-panel"
  width="auto"
  height="auto"
  maxWidth="500px"
  [shading]="shading"
  [container]="container"
  [showTitle]="false"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="visible"
  (visibleChange)="visibleChange.emit($event)"
>
  <div
    class="custom-load-panel-content"
    [ngClass]="{
      'custom-load-panel-with-load-indicator': showLoadIndicator,
      'custom-load-panel-with-message': hasMessage
    }"
    *dxTemplate="let data of 'content'"
  >
    <div class="custom-load-indicator-content" *ngIf="showLoadIndicator">
      <dx-load-indicator [visible]="true"></dx-load-indicator>
    </div>
    <div class="custom-load-message-content" *ngIf="hasMessage">
      <h6 class="custom-load-message">{{ message }}</h6>
    </div>
  </div>
</dx-popup>
