import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import HttpStatusCodes from 'http-status-codes';

export const HttpStatus = HttpStatusCodes;

interface ErrorOptions {
  error?: any;
  headers?: HttpHeaders;
  url?: string;
}

export class HttpUtils {
  static createNotFound(options?: ErrorOptions) {
    return this.createErrorResponse(HttpStatus.NOT_FOUND, options);
  }

  static createBadRequest(options?: ErrorOptions) {
    return this.createErrorResponse(HttpStatus.BAD_REQUEST, options);
  }

  private static createErrorResponse(
    statusCode: number,
    options?: ErrorOptions
  ) {
    if (!options) {
      options = {};
    }
    return new HttpErrorResponse({
      error: options.error,
      headers: options.headers,
      url: options.url,
      status: statusCode,
      statusText: HttpStatus.getStatusText(statusCode),
    });
  }
}
