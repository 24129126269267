import { AuctionLotStatusEnum } from "./auction-lot-status.enum";
import { AuctionOwnerModel } from "./auction-owner.model";
import { BaseModel } from "./base.model";
import { BreedModelLike } from "./breed.model";
import { ShoppingResumeModel } from "./shopping-resume.model";

export class MyShoppingLotModelLike
{
    loteId?: string;
    auctionId?: string;
    name?: string;
    description?: string;
    breed?: BreedModelLike;
    gender?: string;
    paidComission: boolean;
    paidRelease: boolean;
    status?: AuctionLotStatusEnum;
    incrementMin?: number;
    bids?: number;
    imageUrl?: string;
    dateEnd?: Date;
    birthDay?: Date;
    auctionOwner?: AuctionOwnerModel;
    shoppingResume?: ShoppingResumeModel;
}

export class MyShoppingLotModel
    extends BaseModel<MyShoppingLotModelLike, MyShoppingLotModel>
    implements MyShoppingLotModelLike
{
    loteId?: string;
    auctionId?: string;
    name?: string;
    description?: string;
    breed?: BreedModelLike;
    gender?: string;
    status?: AuctionLotStatusEnum;
    paidComission: boolean;
    paidRelease: boolean;
    incrementMin?: number;
    bids?: number;
    imageUrl?: string;
    dateEnd?: Date;
    birthDay?: Date;
    auctionOwner?: AuctionOwnerModel;
    shoppingResume?: ShoppingResumeModel;

     apply(item: Partial<MyShoppingLotModelLike>)
     {
        this.loteId = item?.loteId;
        this.auctionId = item?.auctionId;
        this.name = item?.name;
        this.description = item?.description;
        this.breed = item?.breed;
        this.gender = item?.gender;
        this.status = item?.status;
        this.paidComission = item?.paidComission;
        this.paidRelease = item?.paidRelease;
        this.incrementMin = item?.incrementMin;
        this.bids = item?.bids;
        this.imageUrl = item?.imageUrl;
        this.dateEnd = this.parseDateTime(item?.dateEnd);
        this.birthDay = this.parseDateTime(item?.birthDay);
        this.auctionOwner = item?.auctionOwner;
        this.shoppingResume = item?.shoppingResume;
     }
}