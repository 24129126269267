import { BaseModel } from './base.model';
import { Installment } from './installment';

export class MyShoppingPaymentModelLike
{
    id?: string;
    auctionLoteId?: string;
    buyerId?: string;
    value?: number;
    amountParcelas?: number;
    amountSettled?: number;
    commission?: number;
    status?: string;
    paidComission?: boolean;
    paidRelease?: boolean;
    createAt?: Date;
    updateAt?: Date;
    shoppingInstallment: Installment[];
}

export class MyShoppingPaymentModel
    extends BaseModel<MyShoppingPaymentModelLike, MyShoppingPaymentModel>
    implements MyShoppingPaymentModelLike
{
    id?: string;
    auctionLoteId?: string;
    buyerId?: string;
    value?: number;
    amountParcelas?: number;
    amountSettled?: number;
    commission?: number;
    status?: string;
    paidComission?: boolean;
    paidRelease?: boolean;
    createAt?: Date;
    updateAt?: Date;
    shoppingInstallment: Installment[];

    apply(item: Partial<MyShoppingPaymentModelLike>)
    {
        this.id = item?.id;
        this.auctionLoteId = item?.auctionLoteId;
        this.buyerId = item?.buyerId;
        this.value = item?.value;
        this.amountParcelas = item?.amountParcelas;
        this.amountSettled = item?.amountSettled;
        this.commission = item?.commission;
        this.status = item?.status;
        this.paidComission = item?.paidComission;
        this.paidRelease = item?.paidRelease;
        this.createAt = this.parseDateTime(item?.createAt);
        this.updateAt = this.parseDateTime(item?.updateAt);
        this.shoppingInstallment = item?.shoppingInstallment;
    }
}