<app-header class="header"></app-header>

<div class="layout-body">
  <dx-scroll-view class="full-height-scrollable full-width">
    <div class="content" [@fadeInUpOnEnter]>
      <ng-content></ng-content>
    </div>

    <app-footer class="footer"></app-footer>
  </dx-scroll-view>
</div>
