import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { CustomLazyLoadImageModule } from 'src/app/shared/helpers/lazyload-image/lazyload-image.module';
import { AuthLayoutComponent } from './auth-layout.component';

@NgModule({
  imports: [CommonModule, DxScrollViewModule, CustomLazyLoadImageModule, RouterModule],
  declarations: [AuthLayoutComponent],
  exports: [AuthLayoutComponent],
})
export class AuthLayoutModule {}
