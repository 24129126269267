import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { LayoutModeEnum } from './shared/models/layout-mode.model';
import { AuctionLotChangeResolver } from './shared/resolvers/auction-lot-change.resolver';
import { AuctionLotDetailRunningResolver } from './shared/resolvers/auction-lot.resolver';
import { AuctionDetailRunningResolver } from './shared/resolvers/auction.resolver';
import { AuctionChangeResolver } from './shared/resolvers/aution-change.resolver';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((x) => x.HomePageModule),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'quem-somos',
    loadChildren: () =>
      import('./pages/we-are/we-are.module').then((x) => x.WeAreModule),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'passo-a-passo',
    loadChildren: () =>
      import('./pages/step-by-step/step-by-step.module').then((x) => x.StepByStepModule),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'step-by-step',
    loadChildren: () =>
      import('./pages/step-by-step/step-by-step.module').then((x) => x.StepByStepModule),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'auctions-running/:auctionId',
    loadChildren: () =>
      import(
        './pages/auction-details-running/auction-details-running.module'
      ).then((x) => x.AuctionDetailsRunningModule),
    canActivate: [AuthGuard],
    resolve: {
      auctionModel: AuctionDetailRunningResolver,
    },
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'auctions-running/lots/:auctionLotId',
    loadChildren: () =>
      import(
        './pages/auction-lot-details-running/auction-lot-details-running.module'
      ).then((x) => x.AuctionLotDetailsRunningModule),
    canActivate: [AuthGuard],
    resolve: {
      auctionLotModel: AuctionLotDetailRunningResolver,
    },
    data: {
      layoutMode: LayoutModeEnum.Default,
    },
  },
  {
    path: 'my-throws',
    loadChildren: () =>
      import('./pages/my-throws/my-throws.module').then(
        (x) => x.MyThrowsModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
    },
  },
  {
    path: 'my-shopping',
    loadChildren: () =>
      import('./pages/my-shopping/my-shopping.module').then(
        (x) => x.MyShoppingModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
    },
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./pages/auth/auth.module').then((x) => x.AuthModule),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Auth,
      onlyNotLogged: true,
    },
  },
  {
    path: 'my-card-list',
    loadChildren: () =>
      import('./pages/my-card-list/my-card-list.module').then(
        (x) => x.MyCardListModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlyBuyer: true,
    },
  },
  {
    path: 'my-card-list/new',
    loadChildren: () =>
      import('./pages/card-register/card-register.module').then(
        (x) => x.CardRegisterModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlyBuyer: true,
    },
  },
  {
    path: 'my-auction-list',
    loadChildren: () =>
      import('./pages/my-auction-list/my-auction-list.module').then(
        (x) => x.MyAuctionListModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  {
    path: 'my-auction-list/new',
    loadChildren: () =>
      import('./pages/auction-register/auction-register.module').then(
        (x) => x.AuctionRegisterModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  {
    path: 'my-auction-list/:auctionId',
    loadChildren: () =>
      import('./pages/auction-register/auction-register.module').then(
        (x) => x.AuctionRegisterModule
      ),
    canActivate: [AuthGuard],
    resolve: {auctionRegister: AuctionChangeResolver},
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  {
    path: 'my-auction-list/:auctionId/lots',
    loadChildren: () =>
      import('./pages/my-auction-lot-list/my-auction-lot-list.module').then(
        (x) => x.MyAuctionLotListModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  {
    path: 'my-auction-list/:auctionId/lots/new',
    loadChildren: () =>
      import('./pages/auction-lot-register/auction-lot-register.module').then(
        (x) => x.AuctionLotRegisterModule
      ),
    canActivate: [AuthGuard],
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  {
    path: 'my-auction-list/:auctionId/lots/:auctionLotId',
    loadChildren: () =>
      import('./pages/auction-lot-register/auction-lot-register.module').then(
        (x) => x.AuctionLotRegisterModule
      ),
    canActivate: [AuthGuard],
    resolve: {auctionLotRegister: AuctionLotChangeResolver},
    data: {
      layoutMode: LayoutModeEnum.Default,
      onlyLogged: true,
      onlySeller: true,
    },
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuctionDetailRunningResolver,
    AuctionLotDetailRunningResolver,
    AuctionChangeResolver,
    AuctionLotChangeResolver
  ],
})
export class AppRoutingModule {}
