import moment from 'moment';
import { Type } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isObjectLike from 'lodash/isObjectLike';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import chain from 'lodash/chain';
import {
  IPlaneable,
  ICloneable,
  IApplyable,
  IEqualable,
} from '../helpers/interfaces';
import { ObjectUtils } from '../utils/object.utils';

export interface IEntity<T = number> {
  OBJECTID?: T;

  isNewRecord(): boolean;
}

export abstract class AbstractModel<I = any, O extends BaseModel<I, O> = any>
  implements IApplyable<I>, IPlaneable, ICloneable<O>, IEqualable<O> {
  clone(): O {
    return cloneDeep(this as any);
  }

  equals(item?: O | any): boolean {
    if (!item || !(item instanceof this.constructor)) {
      return false;
    }

    return ObjectUtils.equals(this, item);
  }

  plain(): any {
    const aux: any = {};
    let keys = Object.keys(this);

    let itemAux: any;
    for (const key of keys) {
      if (key.startsWith('_')) {
        continue;
      }
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.plain)) {
          itemAux = itemAux.plain();
        } else {
          itemAux = ObjectUtils.plain(itemAux);
        }
      }

      aux[key] = itemAux;
    }

    keys = ObjectUtils.getGettersKeysFrom(this);
    for (const key of keys) {
      if (key.startsWith('_')) {
        continue;
      }
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.plain)) {
          itemAux = itemAux.plain();
        } else {
          itemAux = ObjectUtils.plain(itemAux);
        }
      }

      aux[key] = itemAux;
    }

    return aux;
  }

  toJSON(): any {
    const aux: any = {};
    let keys = Object.keys(this);

    let itemAux: any;
    for (const key of keys) {
      if (key.startsWith('_')) {
        continue;
      }
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.toJSON)) {
          itemAux = itemAux.toJSON();
        } else {
          itemAux = ObjectUtils.toJSON(itemAux);
        }
      }

      aux[key] = itemAux;
    }

    keys = ObjectUtils.getGettersKeysFrom(this);
    for (const key of keys) {
      if (key.startsWith('_')) {
        continue;
      }
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.toJSON)) {
          itemAux = itemAux.toJSON();
        } else {
          itemAux = ObjectUtils.toJSON(itemAux);
        }
      }

      aux[key] = itemAux;
    }
    return aux;
  }

  abstract apply(item: Partial<I>);

  protected parseDate(value, defaultValue?) {
    if (!value) {
      value = defaultValue;
    }
    if (value) {
      value = moment(value).utcOffset(0).format('YYYY-MM-DD');
      return moment(value).toDate();
    }

    return null;
  }

  protected parseDateTime(value: any, defaultValue?: any) {
    if (!value) {
      value = defaultValue;
    }
    if (value) {
      return moment(value).toDate();
    }

    return undefined;
  }

  protected formatDate(date: Date) {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-MM-DD');
  }

  protected formatDateTime(date: Date) {
    if (!date) {
      return null;
    }
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  protected isEmpty(value) {
    return typeof value === 'undefined' || value === null || value === '';
  }

  protected chooseValue<T>(
    item: any,
    keys: string[],
    defaultValue?: any
  ): T | undefined {
    const value = chain(item)
      .at(...keys)
      .filter((x) => {
        return !this.isEmpty(x);
      })
      .first()
      .value();

    if (isUndefined(value)) {
      return defaultValue;
    }

    return value;
  }

  isAnyFieldEmpty(...fields: Array<keyof O>) {
    if (!fields || !fields.length) {
      fields = Object.keys(this) as any;
    }
    const $this: any = this;

    for (const field of fields) {
      if (this.isEmpty($this[field])) {
        return true;
      }
    }

    return false;
  }
}

const modelKeysMap = new Map<Type<BaseModel>, string[]>();

export abstract class BaseModel<
  I = any,
  O extends BaseModel<I, O> = any
> extends AbstractModel<I, O> {
  constructor(item?: I) {
    super();
    this.apply(item || ({} as any));
  }

  static createArrayOrDefault<O extends BaseModel<I, O>, I = any>(
    type: Type<O>,
    items: I[]
  ): O[] {
    const result: O[] = items?.map((x) => this.createOrDefault(type, x)) ?? [];
    return result;
  }

  static createOrDefault<O extends BaseModel<I, O>, I = any>(
    type: Type<O>,
    item: I
  ): O {
    if (item) {
      return new type(item);
    } else {
      return null as any;
    }
  }

  static keys<O extends BaseModel>(type: Type<O>) {
    let keys: Array<keyof O>;
    if (!modelKeysMap.has(type)) {
      const item: any = {};
      const instance: O = this.createOrDefault(type, item) as any;
      const fields = Object.keys(instance) as any;
      const getters = ObjectUtils.getGettersKeysFrom(instance) as any;
      keys = new Array(fields.length + getters.length);

      let realSize = 0;
      let key: string;
      for (let i = 0; i < keys.length; ++i) {
        if (i < fields.length) {
          key = fields[i];
        } else {
          key = getters[i - fields.length];
        }
        if (key && !key.startsWith('_')) {
          keys[i] = key as any;
          ++realSize;
        }
      }

      if (realSize < keys.length) {
        const newKeys = new Array<keyof O>(realSize);
        let j = 0;
        for (const aux of keys) {
          if (aux) {
            newKeys[j++] = aux;
          }
        }
        keys = newKeys;
      }

      modelKeysMap.set(type, keys as any);
    } else {
      keys = modelKeysMap.get(type) as any;
    }
    return keys.slice();
  }

  plain() {
    const type: Type<O> = (this as any).constructor;
    const keys: string[] = BaseModel.keys(type) as any;

    const aux: any = {};

    let itemAux: any;
    for (const key of keys) {
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.plain)) {
          itemAux = itemAux.plain();
        } else {
          itemAux = ObjectUtils.plain(itemAux);
        }
      }

      aux[key] = itemAux;
    }
    return aux;
  }

  toJSON() {
    const type: Type<O> = (this as any).constructor;
    const keys: string[] = BaseModel.keys(type) as any;

    const aux: any = {};

    let itemAux: any;
    for (const key of keys) {
      itemAux = (this as any)[key];
      if (isObjectLike(itemAux)) {
        if (isFunction(itemAux.toJSON)) {
          itemAux = itemAux.toJSON();
        } else {
          itemAux = ObjectUtils.toJSON(itemAux);
        }
      }

      aux[key] = itemAux;
    }
    return aux;
  }
}
