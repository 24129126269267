import { Injectable } from '@angular/core';
import { Attributes, IntersectionObserverHooks } from 'ng-lazyload-image';
import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import { map, mergeMap } from 'rxjs/operators';
import {
  CacheFactoryService,
  ICacheService,
} from '../../services/cache-factory.service';
import { BlobUtils } from '../../utils/blob.utils';
import { ColorUtils } from '../../utils/color.utils';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
  private cacheService: ICacheService;
  private defaultSvg: string;

  constructor(cacheFactory: CacheFactoryService) {
    super();
    this.cacheService = cacheFactory.getOrCreate('images');
  }

  setup(attrs: Attributes) {
    if (!this.defaultSvg) {
      const blob = this.createSvgBlobByColor('#E2E2E2');
      this.defaultSvg = URL.createObjectURL(blob);
    }

    attrs.defaultImagePath = this.defaultSvg;

    if (attrs.element instanceof HTMLDivElement) {
      attrs.element.style.transition = 'background-image 0.3s ease-in-out 0.1s';
    } else if (attrs.element instanceof HTMLImageElement) {
      attrs.element.style.transition = 'all 0.3s ease-in-out 0.1s';
    }

    super.setup(attrs);
  }

  loadImage(attrs: Attributes): Observable<string> {
    if (this.skipLazyLoading(attrs)) {
      return of(attrs.imagePath);
    }

    const path = attrs.imagePath;
    return this.cacheService.getCached({
      path,
      source: this._loadImageSource,
    });
  }

  createSvgBlobByColor(color: string) {
    const svg = this.createSvgByColor(color);
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    return blob;
  }

  createSvgByColor(color: string) {
    return `<svg width="20" height="20"
        xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
      <rect width="100%" height="100%" fill="${color}"></rect>
    </svg>
    `;
  }

  private _loadImageSource = (path: string) => {
    let observable: Observable<string>;

    if (!path.startsWith('blob:')) {
      let observableBlob: Observable<Blob>;
      if (ColorUtils.isColor(path)) {
        observableBlob = new Observable<Blob>((subscriber) => {
          const blob = this.createSvgBlobByColor(path);
          subscriber.next(blob);
          subscriber.complete();
        });
      } else {
        observableBlob = fromFetch(path).pipe(
          mergeMap((response) => {
            return response.blob();
          })
        );
      }

      observable = observableBlob.pipe(
        map((blob) => {
          return URL.createObjectURL(blob);
        })
      );
    } else {
      observable = of(path);
    }

    return observable;
  };
}
