import { HttpParams } from '@angular/common/http';
import merge from 'lodash/merge';
import { IApplyable } from '../interfaces';
import moment from 'moment';
import { PageParams, PageParamsLike } from './page-params';

interface SortParam {
  selector: string;
  desc?: boolean;
}

type FilterExprClause = [string, string, any] | any;
type FilterExprSubClause =
  | FilterExprClause
  | [string, FilterExprClause]
  | [FilterExprClause | string | FilterExprClause]
  | [FilterExprClause | string | FilterExprClause | string | FilterExprClause]
  | [
      | FilterExprClause
      | string
      | FilterExprClause
      | string
      | FilterExprClause
      | string
      | FilterExprClause
    ]
  | Array<FilterExprClause>;
type FilterExprSubSubClause =
  | FilterExprSubClause
  | [string, FilterExprSubClause]
  | [FilterExprSubClause | string | FilterExprSubClause]
  | [
      | FilterExprSubClause
      | string
      | FilterExprSubClause
      | string
      | FilterExprSubClause
    ]
  | [
      | FilterExprSubClause
      | string
      | FilterExprSubClause
      | string
      | FilterExprSubClause
      | string
      | FilterExprSubClause
    ]
  | Array<FilterExprSubClause>;
type FilterExpr = FilterExprSubSubClause[];

export interface LoadOptionsParamsLike extends PageParamsLike {
  extras?: any;
  sort?: SortParam[];
  filter?: FilterExpr;
  searchOperation?: string;
  searchExpr?: any;
  searchValue?: any;

  sortOptional?: SortParam[];
  filterOptional?: FilterExpr;
  skipOptional?: number;
  takeOptional?: number;
  searchOperationOptional?: string;
  searchExprOptional?: any;
  searchValueOptional?: any;
}

export class LoadOptionsParams
  extends PageParams<LoadOptionsParams, LoadOptionsParamsLike>
  implements LoadOptionsParamsLike, IApplyable<LoadOptionsParamsLike> {
  static STRING_SYMBOL = `'`;

  extras?: any;
  sort?: SortParam[];
  filter?: FilterExpr;
  searchOperation?: string;
  searchExpr?: any;
  searchValue?: any;

  filterOptional?: FilterExpr;
  sortOptional?: SortParam[];
  searchOperationOptional?: string;
  searchExprOptional?: any;
  searchValueOptional?: any;

  static adaptToQueryStrFilter(filter?: any) {
    let result: string;
    if (Array.isArray(filter) && filter.length) {
      result = JSON.stringify(filter);
    } else {
      result = '';
    }
    return result;
  }

  static adaptToQueryStrSort(sort: SortParam[]) {
    let result: string;
    if (Array.isArray(sort) && sort.length) {
      result = JSON.stringify(sort);
    } else {
      result = '';
    }
    return result;
  }

  private static parseParamStr(value: any) {
    if (typeof value === 'object') {
      if (value instanceof Date) {
        value = moment(value).format('YYYY-MM-DD');
        value =
          LoadOptionsParams.STRING_SYMBOL +
          value +
          LoadOptionsParams.STRING_SYMBOL;
      } else {
        value = JSON.stringify(value);
      }
    } else if (typeof value !== 'string') {
      value = String(value);
    }

    return value;
  }

  apply(item: Partial<LoadOptionsParamsLike>) {
    super.apply(item);
    this.extras = item?.extras;
    this.sort = item?.sort;
    this.filter = item?.filter;
    this.searchOperation = item?.searchOperation;
    this.searchExpr = item?.searchExpr;
    this.searchValue = item?.searchValue;

    this.sortOptional = item?.sortOptional;
    this.filterOptional = item?.filterOptional;
    this.searchOperationOptional = item?.searchOperationOptional;
    this.searchExprOptional = item?.searchExprOptional;
    this.searchValueOptional = item?.searchValueOptional;
  }

  setExtras(extras: any) {
    this.extras = merge(this.extras || {}, extras || {});
    return this;
  }

  setPrependExtras(extras: any) {
    this.extras = merge(extras || {}, this.extras || {});
    return this;
  }

  appendFilter(filterExpr: FilterExprSubSubClause[], groupingExpr = 'and') {
    if (!this.filter || !this.filter.length) {
      this.filter = filterExpr;
    } else {
      this.filter = [this.filter as any, groupingExpr, filterExpr];
    }
    return this;
  }

  prependSort(...sort: SortParam[]) {
    if (!this.sort) {
      this.sort = [];
    }
    Array.prototype.push.apply(sort, this.sort);
    this.sort = sort;
    return this;
  }

  appendSort(...sort: SortParam[]) {
    if (!this.sort) {
      this.sort = [];
    }
    Array.prototype.push.apply(this.sort, sort);
    return this;
  }

  appendFilterOptional(
    filterExpr: FilterExprSubSubClause[],
    groupingExpr = 'and'
  ) {
    if (!this.filterOptional || !this.filterOptional.length) {
      this.filterOptional = filterExpr;
    } else {
      this.filterOptional = [
        this.filterOptional as any,
        groupingExpr,
        filterExpr,
      ];
    }
    return this;
  }

  prependSortOptional(...sort: SortParam[]) {
    if (!this.sortOptional) {
      this.sortOptional = [];
    }
    Array.prototype.push.apply(sort, this.sortOptional);
    this.sortOptional = sort;
    return this;
  }

  appendSortOptional(...sort: SortParam[]) {
    if (!this.sortOptional) {
      this.sortOptional = [];
    }
    Array.prototype.push.apply(this.sortOptional, sort);
    return this;
  }

  toHttpParams() {
    let params = super.toHttpParams();
    params = this.adaptToQueryStr(params);

    if (this.extras) {
      const keys = Object.keys(this.extras);
      for (const key of keys) {
        if (this.extras[key] === null) {
          continue;
        }
        params = params.set(
          key,
          LoadOptionsParams.parseParamStr(this.extras[key])
        );
      }
    }
    return params;
  }

  resolveFilters() {
    let filter: FilterExpr;
    if (this.filter && this.filter.length) {
      filter = this.filter;
    } else {
      filter = this.filterOptional ?? [];
    }

    const searchFilter = this.generateSearchFilterOptionIfCan();
    if (searchFilter) {
      filter =
        filter?.length > 0 ? [filter, 'and', searchFilter] : searchFilter;
    }

    return filter;
  }

  resolveSorters() {
    let sort: SortParam[];
    if (this.sort && this.sort.length) {
      sort = this.sort;
    } else {
      sort = this.sortOptional ?? [];
    }

    return sort;
  }

  private adaptToQueryStr(params: HttpParams): HttpParams {
    const filter = this.resolveFilters();
    if (filter && filter.length) {
      const filterClause = LoadOptionsParams.adaptToQueryStrFilter(filter);
      params = params.set('filter', filterClause);
    }

    const sort = this.resolveSorters();
    if (sort && sort.length) {
      const sortParam = LoadOptionsParams.adaptToQueryStrSort(sort);
      params = params.set('sort', sortParam);
    }

    return params;
  }

  private resolveSearchExpr() {
    return this.searchExpr ?? this.searchExprOptional;
  }

  private resolveSearchOperation() {
    return this.searchOperation ?? this.searchOperationOptional;
  }

  private resolveSearchValue() {
    return this.searchValue ?? this.searchValueOptional;
  }

  private generateSearchFilterOptionIfCan() {
    const searchExpr = this.resolveSearchExpr();
    const searchOperation = this.resolveSearchOperation();
    const searchValue = this.resolveSearchValue();
    if (searchExpr && searchOperation && searchValue !== undefined) {
      if (Array.isArray(searchExpr)) {
        const filter: any[] = [];
        for (let i = 0; i < searchExpr.length; ++i) {
          if (i > 0) {
            filter.push('or');
          }

          filter.push([searchExpr[i], searchOperation, searchValue]);
        }

        return filter.length ? filter : undefined;
      } else {
        return [searchExpr, searchOperation, searchValue];
      }
    }
    return undefined;
  }
}
