import { Injectable } from '@angular/core';
import { HubConnectionBuilder, HubConnection } from '@microsoft/signalr';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AppInfoService } from './app-info.service';

@Injectable()
export class HubConnectionService {
  private connection: HubConnection;

  private startPromise: Promise<any>;

  constructor(private appInfo: AppInfoService) {}

  invoke(methodName: string, ...args: any[]) {
    return this.startIfNeeded().pipe(
      mergeMap(() => {
        return from(this.connection.invoke(methodName, ...args));
      })
    );
  }

  on<T = any>(methodName) {
    return new Observable<T>((subscriber) => {
      const callback = (data: T) => {
        subscriber.next(data);
      };

      const connection = this.getConnection();

      connection.on(methodName, callback);

      subscriber.add(() => {
        connection.off(methodName, callback);
      });
    });
  }

  private startIfNeeded() {
    if (!this.startPromise) {
      this.startPromise = this.getConnection().start();
    }

    return from(this.startPromise);
  }

  private getConnection() {
    if (!this.connection) {
      const url = this.appInfo.normalizeUrl('~horseHub');

      this.connection = new HubConnectionBuilder()
        .withAutomaticReconnect()
        .withUrl(url)
        .build();
    }

    return this.connection;
  }
}
