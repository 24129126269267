import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class HorseGenderService {
  private genders = ['Macho castrado', 'Fêmea', 'Garanhão'].map((value) => {
    return { value };
  });

  listGenders() {
    return of(this.genders);
  }

  getByCode(code: string) {
    return this.listGenders().pipe(
      map((list) => list.find((x) => x.value === code))
    );
  }
}
