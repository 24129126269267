import { SvgLoader } from 'angular-svg-icon';
import { Observable, of } from 'rxjs';
import { fromFetch } from 'rxjs/fetch';
import {
  TransferState,
  makeStateKey,
  StateKey,
} from '@angular/platform-browser';
import { flatMap, shareReplay, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class SvgBrowserLoader implements SvgLoader {
  private cacheMap = new Map<string, Observable<string>>();

  constructor(private transferState: TransferState) {}

  getSvg(url: string): Observable<string> {
    const key = makeStateKey<string>('transfer-svg:' + url);
    const data = this.transferState.get(key, null);
    if (data) {
      return of(data);
    } else {
      if (this.cacheMap.has(url)) {
        return this.cacheMap.get(url)!;
      } else {
        const observable = fromFetch(url, {
          selector: (response) => response.text(),
        }).pipe(
          tap((value) => {
            this.transferState.set(key, value);
            this.cacheMap.delete(url);
          }),
          shareReplay(1)
        );
        this.cacheMap.set(url, observable);
        return observable;
      }
    }
  }
}
