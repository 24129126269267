import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  tap,
  mergeMap,
  share,
  shareReplay,
} from 'rxjs/operators';
import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';
import { AuctionLotRegisterModelLike } from '../models/auction-lot-register.model';
import {
  AuctionLotEventModel,
  AuctionLotThrowModelLike,
} from '../models/auction-lot-event.model';
import { AuctionLotForChangeModel } from '../models/auction-lot-for-change.model';
import { AuctionLotRunningDetailModel } from '../models/auction-lot-running-detail.model';
import { AuctionLotRunningModel } from '../models/auction-lot-running.model';
import { AuctionLotStatusEnum } from '../models/auction-lot-status.enum';
import { AuctionLotThrowRunningModel } from '../models/auction-lot-throw-running.model';
import { AuctionLotModel } from '../models/auction-lot.model';
import { BaseModel } from '../models/base.model';
import { PaginationResult } from '../models/pagination-result.model';
import { HubConnectionService } from './hub-connection.service';
import { EventBusService } from './event-bus.service';

@Injectable()
export class AuctionLotService {
  private _auctionLotObservables = new Map<string, Observable<any>>();
  private _auctionLotFinishAtObservables = new Map<string, Observable<Date>>();
  private _auctionLotStatusObservables = new Map<
    string,
    Observable<AuctionLotStatusEnum>
  >();
  private _auctionLotInstallmentPriceObservables = new Map<
    string,
    Observable<number>
  >();
  private _auctionLotThrowsCountObservables = new Map<
    string,
    Observable<number>
  >();
  private _auctionLotLastThrowCountObservables = new Map<
    string,
    Observable<AuctionLotThrowModelLike>
  >();

  constructor(
    private http: HttpClient,
    private hubConnectionService: HubConnectionService,
    private eventBus: EventBusService
  ) {}

  queryLotsRunningByAuction(
    auctionId: string,
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionLotRunningModel>> {
    options = new LoadOptionsParams(options).appendFilter([
      'auctionId',
      '=',
      auctionId,
    ]);
    return this.queryLotsRunning(options);
  }

  queryLotsRunning(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionLotRunningModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionLotRunningModel>>(`~api/Auction/LotesList`, {
        params,
      })
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionLotRunningModel,
            res.data
          );
          return res;
        })
      );
  }

  queryMyLotsWithThrowsRunning(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionLotThrowRunningModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionLotThrowRunningModel>>(`~api/Bet/MyBets`, {
        params,
      })
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(
            AuctionLotThrowRunningModel,
            res.data
          );
          return res;
        })
      );
  }

  getLotRunningDetail(
    auctionLotId: string
  ): Observable<AuctionLotRunningDetailModel> {
    return this.http
      .get<AuctionLotRunningDetailModel>(`~api/Auction/LoteDetail`, {
        params: { Id: auctionLotId },
      })
      .pipe(
        map((res) => {
          res = BaseModel.createOrDefault(AuctionLotRunningDetailModel, res);
          return res;
        })
      );
  }

  getAuctionLotForChange(
    auctionLotId: string
  ): Observable<AuctionLotForChangeModel> {
    return this.http
      .get<AuctionLotForChangeModel>(`~api/Auction/GetAuctionLoteForChange`, {
        params: { Id: auctionLotId },
      })
      .pipe(
        map((res) => {
          res = BaseModel.createOrDefault(AuctionLotForChangeModel, res);
          return res;
        })
      );
  }

  doThrowInLot(
    auctionId: string,
    auctionLotId: string,
    incrementValue: number
  ) {
    return this.http.post<any>(`~api/Bet`, {
      auctionId,
      loteId: auctionLotId,
      incremental: incrementValue,
    });
  }

  observeAuctionLot(auctionLotId: string): Observable<AuctionLotEventModel> {
    return new Observable<AuctionLotEventModel>((subscriber) => {
      let observable: Observable<AuctionLotEventModel>;

      if (!this._auctionLotObservables.has(auctionLotId)) {
        observable = this.hubConnectionService
          .invoke('CreateConnectLote', auctionLotId)
          .pipe(
            shareReplay(1),
            mergeMap(() => {
              return this.hubConnectionService.on('OnLoteBetChange').pipe(
                filter((data) => {
                  return data?.auctionLoteId === auctionLotId;
                })
              );
            }),
            filter((x) => !!x),
            map((data) => {
              return BaseModel.createOrDefault(AuctionLotEventModel, data);
            }),
            finalize(() => {
              // this._auctionLotObservables.delete(auctionLotId);
            }),
            share()
          );
        this._auctionLotObservables.set(auctionLotId, observable);
      } else {
        observable = this._auctionLotObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionLotFinishAt(auctionLotId: string): Observable<Date> {
    return new Observable((subscriber) => {
      let observable: Observable<Date>;

      if (!this._auctionLotFinishAtObservables.has(auctionLotId)) {
        observable = this.observeAuctionLot(auctionLotId).pipe(
          map((data) => {
            return data?.dateEnd;
          }),
          filter((x) => !!x),
          distinctUntilChanged(
            (x, y) => x === y,
            (x) => x?.getTime()
          ),
          finalize(() => {
            this._auctionLotFinishAtObservables.delete(auctionLotId);
          }),
          share()
        );
        this._auctionLotFinishAtObservables.set(auctionLotId, observable);
      } else {
        observable = this._auctionLotFinishAtObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionLotStatus(
    auctionLotId: string
  ): Observable<AuctionLotStatusEnum> {
    return new Observable((subscriber) => {
      let observable: Observable<AuctionLotStatusEnum>;

      if (!this._auctionLotStatusObservables.has(auctionLotId)) {
        observable = this.observeAuctionLot(auctionLotId).pipe(
          map((data) => {
            return data?.status;
          }),
          filter((x) => !!x),
          distinctUntilChanged(),
          finalize(() => {
            this._auctionLotStatusObservables.delete(auctionLotId);
          }),
          share()
        );
        this._auctionLotStatusObservables.set(auctionLotId, observable);
      } else {
        observable = this._auctionLotStatusObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionLotInstallmentPrice(auctionLotId: string): Observable<number> {
    return new Observable((subscriber) => {
      let observable: Observable<number>;

      if (!this._auctionLotInstallmentPriceObservables.has(auctionLotId)) {
        observable = this.observeAuctionLotLastThrow(auctionLotId).pipe(
          map((data) => {
            return data?.price;
          }),
          filter((x) => typeof x === 'number'),
          distinctUntilChanged(),
          finalize(() => {
            this._auctionLotInstallmentPriceObservables.delete(auctionLotId);
          }),
          share()
        );
        this._auctionLotInstallmentPriceObservables.set(
          auctionLotId,
          observable
        );
      } else {
        observable =
          this._auctionLotInstallmentPriceObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionLotThrowsCount(auctionLotId: string): Observable<number> {
    return new Observable((subscriber) => {
      let observable: Observable<number>;

      if (!this._auctionLotThrowsCountObservables.has(auctionLotId)) {
        observable = this.observeAuctionLot(auctionLotId).pipe(
          map((data) => {
            return data?.bids;
          }),
          filter((x) => typeof x === 'number'),
          distinctUntilChanged(),
          finalize(() => {
            this._auctionLotThrowsCountObservables.delete(auctionLotId);
          }),
          share()
        );
        this._auctionLotThrowsCountObservables.set(auctionLotId, observable);
      } else {
        observable = this._auctionLotThrowsCountObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  observeAuctionLotLastThrow(
    auctionLotId: string
  ): Observable<AuctionLotThrowModelLike> {
    return new Observable((subscriber) => {
      let observable: Observable<AuctionLotThrowModelLike>;

      if (!this._auctionLotLastThrowCountObservables.has(auctionLotId)) {
        observable = this.observeAuctionLot(auctionLotId).pipe(
          map((data) => {
            return data?.lance;
          }),
          filter((x) => !!x),
          finalize(() => {
            this._auctionLotLastThrowCountObservables.delete(auctionLotId);
          }),
          share()
        );
        this._auctionLotLastThrowCountObservables.set(auctionLotId, observable);
      } else {
        observable =
          this._auctionLotLastThrowCountObservables.get(auctionLotId);
      }

      return observable.subscribe(subscriber);
    });
  }

  queryMyLotsByAuction(
    auctionId: string,
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionLotModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<AuctionLotModel>>(
        `~api/Auction/${auctionId}/Lotes`,
        {
          params,
        }
      )
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(AuctionLotModel, res.data);
          return res;
        })
      );
  }

  getLotByIdAndAuction(
    auctionId: string,
    lotId: string
  ): Observable<AuctionLotModel> {
    return this.http
      .get<AuctionLotModel>(`~api/Auction/${auctionId}/Lote/${lotId}`)
      .pipe(
        map((res) => {
          res = BaseModel.createOrDefault(AuctionLotModel, res);
          return res;
        })
      );
  }

  createLot(model: AuctionLotRegisterModelLike): Observable<any> {
    return this.http.post(`~api/Auction/Add/Lote`, model);
  }

  updateLot(model: AuctionLotRegisterModelLike): Observable<any> {
    return this.http.put(`~api/Auction/Change/Lote`, model);
  }

  delete(auctionLoteId: string): Observable<any> {
    return this.http.delete(`~/api/Auction/Remove/AuctionLote`, { params: { auctionLoteId }}).pipe(
      tap((e) => {
        this.eventBus.emit('auctionLote.deleted', auctionLoteId);
      })
    );
  }

}
