import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';
import { BreedModelLike } from '../models/breed.model';
import { PaginationResult } from '../models/pagination-result.model';

@Injectable()
export class BreedService {
  constructor(private http: HttpClient) {}

  query(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<BreedModelLike>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http.get<PaginationResult<BreedModelLike>>(
      `~api/domain/Breeds`,
      { params }
    );
  }

  getById(breedId: string): Observable<BreedModelLike> {
    return this.query({ filter: ['id', '=', breedId], take: 1 }).pipe(
      map((res) => res?.data?.[0])
    );
  }
}
