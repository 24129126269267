<div class="auth-background" [lazyLoad]="bgPath">
  <div class="main-container" [@fadeInUpOnEnter]>
    <dx-scroll-view class="full-height-scrollable">
      <div class="dx-card card-container">
        <div class="header-content padding-gutter">
          <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="logo-content">
                <a [routerLink]="['/']">
                <img src="assets/logo_maisumlance/logo_horses_bc_orange_200x84.png" />
              </a>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="content padding-gutter-top-bottom">
          <ng-content></ng-content>
        </div>
      </div>
    </dx-scroll-view>
  </div>
</div>
