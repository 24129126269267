import { Component } from '@angular/core';
import { fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-default-layout',
  templateUrl: 'default-layout.component.html',
  styleUrls: ['default-layout.component.scss'],
  animations: [fadeInUpOnEnterAnimation({ duration: 300 })],
})
export class DefaultLayoutComponent {}
