import { NgModule } from '@angular/core';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { SvgBrowserLoader } from './svg-loader';

@NgModule({
  imports: [
    AngularSvgIconModule.forRoot({
      loader: {
        provide: SvgLoader,
        useClass: SvgBrowserLoader,
      },
    }),
  ],
  exports: [AngularSvgIconModule],
})
export class SvgIconModule {}
