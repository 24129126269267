<div #btnContainer>
  <span
  *ngIf="!!authorized">
    <i class="dx-icon mdi mdi-bell bell-notification"></i>
    
    <i class="dx-icon mdi mdi-cart my-shopping"
      [routerLink]="['/my-shopping/']"
    ></i>
  </span>

  <dx-button
    *ngIf="!authorized"
    class="custom-button flat-button small-button"
    type="normal"
    stylingMode="outlined"
    icon="mdi mdi-account"
    text="{{ 'user-menu-panel.notLogged.label' | translate }}"
    [routerLink]="['/auth']"
  ></dx-button>

  <dx-drop-down-button
    *ngIf="!!authorized"
    class="custom-button flat-button small-button"
    stylingMode="outlined"
    text="{{ userNameObservable | async }}"
    [items]="menuItems"
    itemTemplate="menuItemTemplate"
    displayExpr="text"
    [dropDownOptions]="dropDownOptions"
  >
    <div
      *dxTemplate="let data of 'menuItemTemplate'"
      class="dx-item-content dx-list-item-content"
    >
      <div *ngIf="!!data.icon" class="dx-list-item-icon-container">
        <app-iconic class="dx-icon dx-list-item-icon" [icon]="data.icon"></app-iconic>
      </div>
      {{ data.text }}
    </div>
  </dx-drop-down-button>
</div>
