import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  tabItems = [];

  private lifecycle = new Subject<any>();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService
      .observeAuthInfo()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((authInfo) => {
        const tabItems = [
          {
            text: 'header.tab-items.home',
            path: '/home',
          },
          {
            text: 'header.tab-items.rules',
            path: '/regras-de-leilao',
          },
          {
            text: 'header.tab-items.contact',
            path: '/contato',
          },
          {
            text: 'header.tab-items.who-we-are',
            path: '/quem-somos',
          },
          {
            text: 'header.tab-items.step-by-step',
            path: '/passo-a-passo',
          },
        ];

        if (authInfo?.isSeller) {
          tabItems.push({
            text: 'header.tab-items.sellers-area',
            path: '/my-auction-list',
          });
        }

        this.tabItems = tabItems;
      });
  }

  ngOnDestroy(): void {
    this.lifecycle.next();
    this.lifecycle.complete();
  }
}
