import { AuctionLotRunningModel } from './auction-lot-running.model';
import { BaseModel } from './base.model';

export interface AuctionRunningDetailModelLike {
  id?: string;
  name?: string;
  owner?: string;
  dateStart?: Date;
  dateEnd?: Date;
  bids?: number;
  availableAnimal?: number;
  imageUrl?: string;
}

export class AuctionRunningDetailModel
  extends BaseModel<AuctionRunningDetailModelLike, AuctionRunningDetailModel>
  implements AuctionRunningDetailModelLike
{
  id?: string;
  name?: string;
  owner?: string;
  dateStart?: Date;
  dateEnd?: Date;
  bids?: number;
  availableAnimal?: number;
  imageUrl?: string;

  apply(item: Partial<AuctionRunningDetailModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.owner = item?.owner ?? 'Teste';
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.bids = item?.bids;
    this.availableAnimal = item?.availableAnimal;
    this.imageUrl = item?.imageUrl;
  }
}
