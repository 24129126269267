import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxDropDownButtonModule } from 'devextreme-angular/ui/drop-down-button';
import { IconicModule } from '../iconic/iconic.module';
import { UserMenuPanelComponent } from './user-menu-panel.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    DxDropDownButtonModule,
    DxButtonModule,
    IconicModule,
  ],
  declarations: [UserMenuPanelComponent],
  exports: [UserMenuPanelComponent],
})
export class UserMenuPanelModule {}
