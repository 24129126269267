import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheFactoryService, ICacheService } from './cache-factory.service';

export interface ZipCodeAddressResult {
  erro?: boolean;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  unidade?: string;
  ibge?: string;
  gia?: string;
}

@Injectable()
export class AddressService {
  private cacheService: ICacheService;

  constructor(
    protected http: HttpClient,
    cacheFactoryService: CacheFactoryService
  ) {
    this.cacheService = cacheFactoryService.getOrCreate('address');
  }

  searchByZipCode(zipCode: string): Observable<ZipCodeAddressResult> {
    return this.cacheService.getCached({
      path: `zipCode/${zipCode}`,
      lifetimeSeconds: 60,
      source: () => {
        return this.http.get<ZipCodeAddressResult>(
          `https://viacep.com.br/ws/${zipCode}/json`
        );
      },
    });
  }
}
