import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  LoadOptionsParams,
  LoadOptionsParamsLike,
} from '../helpers/query-params/load-options-params';
import { BaseModel } from '../models/base.model';
import { CardCreateModelLike } from '../models/card-create.model';
import { CardModel, CardModelLike } from '../models/card.model';
import { PaginationResult } from '../models/pagination-result.model';
import { CacheFactoryService, ICacheService } from './cache-factory.service';
import { EventBusService } from './event-bus.service';

@Injectable()
export class CardsService {
  private cacheService: ICacheService;

  constructor(
    private http: HttpClient,
    private eventBus: EventBusService,
    cacheFactoryService: CacheFactoryService
  ) {
    this.cacheService = cacheFactoryService.getOrCreate('cards');
  }

  queryCards(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<CardModel>> {
    const params = new LoadOptionsParams(options).toHttpParams();
    return this.http
      .get<PaginationResult<CardModel>>(`~api/buyer/cards/get`, { params })
      .pipe(
        map((res) => {
          res.data = BaseModel.createArrayOrDefault(CardModel, res.data);
          return res;
        })
      );
  }

  getCardById(cardId: string): Observable<CardModel> {
    return this.cacheService.getCached({
      path: `cards/${cardId}`,
      removeCacheOnComplete: true,
      source: () => {
        return this.queryCards({ filter: ['id', '=', cardId], take: 1 }).pipe(
          map((res) => {
            return res?.data?.[0];
          })
        );
      },
    });
  }

  create(model: CardCreateModelLike): Observable<any> {
    model = Object.assign({}, model);
    return this.http.post<CardModelLike>(`~api/buyer/card`, model).pipe(
      map((model) => {
        const res = BaseModel.createOrDefault(CardModel, model);
        return res;
      }),
      tap((e) => {
        this.eventBus.emit('card.created', e);
      })
    );
  }

  delete(cardId: string): Observable<any> {
    return this.http.delete(`~api/buyer/card`, { params: { cardId } }).pipe(
      tap((e) => {
        this.eventBus.emit('card.deleted', cardId);
      })
    );
  }
}
