import { BaseModel } from './base.model';

export interface AuctionFinishedModelLike {
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  throwsCount?: number;
  imageBannerInProgressId?: string;
  imageBannerInProgressUrl?: string;
  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;
}

export class AuctionFinishedModel
  extends BaseModel<AuctionFinishedModelLike, AuctionFinishedModel>
  implements AuctionFinishedModelLike
{
  id?: string;
  name?: string;
  created?: Date;
  dateStart?: Date;
  dateEnd?: Date;
  throwsCount?: number;
  imageBannerInProgressId?: string;
  imageBannerInProgressUrl?: string;
  imageBannerLastMinuteId?: string;
  imageBannerLastMinuteUrl?: string;

  apply(item: Partial<AuctionFinishedModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.created = this.parseDateTime(item?.created);
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.throwsCount = item?.throwsCount;
    this.imageBannerInProgressId = item?.imageBannerInProgressId;
    this.imageBannerInProgressUrl = item?.imageBannerInProgressUrl;
    this.imageBannerLastMinuteId = item?.imageBannerLastMinuteId;
    this.imageBannerLastMinuteUrl = item?.imageBannerLastMinuteUrl;
  }
}
