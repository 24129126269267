import { Component, Input } from '@angular/core';
import { fadeInUpOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'app-auth-layout',
  templateUrl: 'auth-layout.component.html',
  styleUrls: ['auth-layout.component.scss'],
  animations: [fadeInUpOnEnterAnimation()],
})
export class AuthLayoutComponent {
  @Input()
  bgPath: string;
}
