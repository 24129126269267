import { LotAttachmentModelLike, LotYoutubeLinkModelLike } from "./auction-lot-register.model";
import { BaseModel } from "./base.model";

export interface AuctionLotForChangeModelLike
{
    id?: string;
    name: string;
    sellerId: string;
    description: string;
    resume: string;
    birthDay: Date;
    breedId: string;
    gender: string;
    height: number;
    studbook: string;
    addressLote: string;
    sellerName: string;
    creatorName: string;
    registerNumber: string;
    priceMin: number;
    priceAmount: number;
    consultantName: string;
    consultantWhatsapp: string;
    consultantPhone: string;
    pedigreeFather: string;
    pedigreeFatherFather: string;
    pedigreeFatherMother: string;
    pedigreeFatherFatherFather: string;
    pedigreeFatherFatherMother: string;
    pedigreeFatherMotherFather: string;
    pedigreeFatherMotherMother: string;
    pedigreeMother: string;
    pedigreeMotherFather: string;
    pedigreeMotherMother: string;
    pedigreeMotherFatherFather: string;
    pedigreeMotherFatherMother: string;
    pedigreeMotherMotherFather: string;
    pedigreeMotherMotherMother: string;
    dateEnd: Date;
    breed: string;
    auctionId: string;
    loteNumber: number;
    loteCode: number;
    attachments?: LotAttachmentModelLike[];
    youtubeLinks?: LotYoutubeLinkModelLike[];
}

export class AuctionLotForChangeModel
    extends BaseModel<AuctionLotForChangeModelLike, AuctionLotForChangeModel>
    implements AuctionLotForChangeModelLike
{
    id?: string;
    name: string;
    sellerId: string;
    description: string;
    resume: string;
    birthDay: Date;
    breedId: string;
    gender: string;
    height: number;
    studbook: string;
    addressLote: string;
    sellerName: string;
    creatorName: string;
    registerNumber: string;
    priceMin: number;
    priceAmount: number;
    consultantName: string;
    consultantWhatsapp: string;
    consultantPhone: string;
    pedigreeFather: string;
    pedigreeFatherFather: string;
    pedigreeFatherMother: string;
    pedigreeFatherFatherFather: string;
    pedigreeFatherFatherMother: string;
    pedigreeFatherMotherFather: string;
    pedigreeFatherMotherMother: string;
    pedigreeMother: string;
    pedigreeMotherFather: string;
    pedigreeMotherMother: string;
    pedigreeMotherFatherFather: string;
    pedigreeMotherFatherMother: string;
    pedigreeMotherMotherFather: string;
    pedigreeMotherMotherMother: string;
    dateEnd: Date;
    breed: string;
    auctionId: string;
    loteNumber: number;
    loteCode: number;
    attachments?: LotAttachmentModelLike[];
    youtubeLinks?: LotYoutubeLinkModelLike[];

    apply(item: Partial<AuctionLotForChangeModelLike>)
    {
        this.id = item?.id;
        this.name = item?.name;
        this.sellerId = item?.sellerId;
        this.description = item?.description;
        this.resume = item?.resume;
        this.birthDay = item?.birthDay;
        this.breedId = item?.breedId;
        this.gender = item?.gender;
        this.height = item?.height;
        this.studbook = item?.studbook;
        this.addressLote = item?.addressLote;
        this.sellerName = item?.sellerName;
        this.creatorName = item?.creatorName;
        this.registerNumber = item?.registerNumber;
        this.priceMin = item?.priceMin;
        this.priceAmount = item?.priceAmount;
        this.consultantName = item?.consultantName;
        this.consultantWhatsapp = item?.consultantWhatsapp;
        this.consultantPhone = item?.consultantPhone;
        this.pedigreeFather = item?.pedigreeFather;
        this.pedigreeFatherFather = item?.pedigreeFatherFather;
        this.pedigreeFatherMother = item?.pedigreeFatherMother;
        this.pedigreeFatherFatherFather = item?.pedigreeFatherFatherFather;
        this.pedigreeFatherFatherMother = item?.pedigreeFatherFatherMother;
        this.pedigreeFatherMotherFather = item?.pedigreeFatherMotherFather;
        this.pedigreeFatherMotherMother = item?.pedigreeFatherMotherMother;
        this.pedigreeMother = item?.pedigreeMother;
        this.pedigreeMotherFather = item?.pedigreeMotherFather;
        this.pedigreeMotherMother = item?.pedigreeMotherMother;
        this.pedigreeMotherFatherFather = item?.pedigreeMotherFatherFather;
        this.pedigreeMotherFatherMother = item?.pedigreeMotherFatherMother;
        this.pedigreeMotherMotherFather = item?.pedigreeMotherMotherFather;
        this.pedigreeMotherMotherMother = item?.pedigreeMotherMotherMother;
        this.dateEnd = item?.dateEnd;
        this.breed = item?.breed;
        this.auctionId = item?.auctionId;
        this.loteNumber = item?.loteNumber;
        this.loteCode = item?.loteCode;
        this.attachments = item?.attachments;
        this.youtubeLinks = item?.youtubeLinks;

    }

}
