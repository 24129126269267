import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuctionLotForChangeModel } from '../models/auction-lot-for-change.model';
import { AuctionLotService } from '../services/auction-lot.service';

@Injectable()
export class AuctionLotChangeResolver
  implements Resolve<AuctionLotForChangeModel>
{
  constructor(private auctionLotService: AuctionLotService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AuctionLotForChangeModel> {
    return this.auctionLotService.getAuctionLotForChange(
      route.params.auctionLotId
    );
  }
}