<header>
  <div class="container">
    <div class="row padding-gutter-top">
      <div class="col-auto margin-gutter-bottom">
        <a [routerLink]="['/']">
          <img class="logo" src="assets/logo_maisumlance/logo_horses_bc_orange_200x84.png" />
        </a>
      </div>
    </div>

    <div class="divider"></div>

    <div class="row">
      <div class="col">
        <ul class="tab-items-container">
          <li
            class="tab-item"
            *ngFor="let item of tabItems"
            [routerLink]="item.path"
            [routerLinkActive]="'active'"
          >
            {{ item.text | translate }}
          </li>
        </ul>
      </div>
      <div class="col-auto d-flex justify-content-end align-items-center">
        <app-user-menu-panel></app-user-menu-panel>
      </div>
    </div>
  </div>
</header>
