import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { FooterModule } from 'src/app/shared/components/footer/footer.module';
import { HeaderModule } from 'src/app/shared/components/header/header.module';
import { DefaultLayoutComponent } from './default-layout.component';

@NgModule({
  imports: [CommonModule, DxScrollViewModule, HeaderModule, FooterModule],
  declarations: [DefaultLayoutComponent],
  exports: [DefaultLayoutComponent],
})
export class DefaultLayoutModule {}
