import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadOptionsParamsLike } from '../helpers/query-params/load-options-params';
import { AddressModelLike } from '../models/address.model';
import { AuthCreateModelLike } from '../models/auth-create.model';
import { PaginationResult } from '../models/pagination-result.model';
import {
  ProfileBusinessSellerCreateModelLike,
  ProfileBuyerCreateModelLike,
  ProfileIndividualSellerCreateModelLike,
} from '../models/profile-create.model';
import { SellerCodeModelLike } from '../models/seller-code.model';
import { UserInfoCreateModelLike } from '../models/user-info-create.model';
import { LoadOptionsUtils } from '../utils/loadoptions.utils';
import { CacheFactoryService, ICacheService } from './cache-factory.service';

const defaultCountryCode = 'BR';

@Injectable()
export class ProfileService {
  private cacheService: ICacheService;

  constructor(private http: HttpClient, cacheFactory: CacheFactoryService) {
    this.cacheService = cacheFactory.getOrCreate('profiles');
  }

  querySellerCodes(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<SellerCodeModelLike>> {
    return this.cacheService
      .getCached({
        path: `~api/domain/Seller/Codes`,
        lifetimeSeconds: 60 * 60,
        source: (path) => {
          return this.http.get<SellerCodeModelLike[]>(path);
        },
      })
      .pipe(
        map((data) => {
          const res = LoadOptionsUtils.loadData(data, options);
          return res;
        })
      );
  }

  getSellerCodeByCode(code: string): Observable<SellerCodeModelLike> {
    return this.cacheService.getCached({
      path: `~api/domain/Seller/Codes/${code}`,
      removeCacheOnComplete: true,
      source: () => {
        return this.querySellerCodes({
          filter: ['code', '=', code],
          take: 1,
        }).pipe(map((res) => res?.data?.find((x) => x?.code === code)));
      },
    });
  }

  createBuyer(
    auth: AuthCreateModelLike,
    userInfo: UserInfoCreateModelLike,
    profileBuyer: ProfileBuyerCreateModelLike
  ): Observable<any> {
    const body = {
      auth: {
        name: userInfo.firstName + ' ' + userInfo.lastName,
        email: auth.email,
        senha: auth.password,
        senhaConfirmacao: auth.password,
      },
      profile: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: auth.email,
        dataNascimento: userInfo.birthDate,
        phoneNumber: userInfo.phoneNumber,
        cpfCnpj: profileBuyer.cpfCnpj,
        rua: profileBuyer.address?.street,
        numero: profileBuyer.address?.addressNumber,
        complemento: profileBuyer.address?.complement,
        bairro: profileBuyer.address?.neighborhood,
        municipio: profileBuyer.address?.city,
        estado: profileBuyer.address?.uf,
        cep: profileBuyer.address?.zipCode,
        countryCode: profileBuyer.address?.countryCode ?? defaultCountryCode,
      },
    };
    return this.http.post<any>(`~api/auth/createAuthBuyer`, body);
  }

  createIndividualSeller(
    auth: AuthCreateModelLike,
    userInfo: UserInfoCreateModelLike,
    profileSeller: ProfileIndividualSellerCreateModelLike
  ): Observable<any> {
    const body = {
      auth: {
        name: userInfo.firstName + ' ' + userInfo.lastName,
        email: auth.email,
        senha: auth.password,
        senhaConfirmacao: auth.password,
      },
      profile: {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        email: auth.email,
        dataNascimento: userInfo.birthDate,
        phoneNumber: userInfo.phoneNumber,
        cpf: profileSeller.cpf,
        nomeFantasia: profileSeller.fantasyName,
        codigoAtividade: profileSeller.activityCode,
        address: this.parseSellerAddressToApi(profileSeller.address),
      },
    };
    return this.http.post<any>(`~api/auth/createAuthSellerIndividual`, body);
  }

  createBusinessSeller(
    auth: AuthCreateModelLike,
    userInfo: UserInfoCreateModelLike,
    profileSeller: ProfileBusinessSellerCreateModelLike
  ): Observable<any> {
    const body = {
      auth: {
        name: userInfo.firstName + ' ' + userInfo.lastName,
        email: auth.email,
        senha: auth.password,
        senhaConfirmacao: auth.password,
      },
      profile: {
        owner: {
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: auth.email,
          dataNascimento: userInfo.birthDate,
          phoneNumber: userInfo.phoneNumber,
          cpf: profileSeller.cpf,
        },
        ownerAddress: this.parseSellerAddressToApi(profileSeller.address),
        description: profileSeller.description,
        businessName: profileSeller.businessName,
        businessPhone: profileSeller.businessPhone,
        businessEmail: profileSeller.businessEmail,
        businessWebSite: profileSeller.businessWebSite,
        cnpj: profileSeller.cnpj,
        statementDescriptor: profileSeller.statementDescriptor,
        codigoAtividade: profileSeller.activityCode,
        businessAddress: this.parseSellerAddressToApi(
          profileSeller.businessAddress
        ),
      },
    };
    return this.http.post<any>(`~api/auth/createAuthSellerBusiness`, body);
  }

  private parseSellerAddressToApi(address: AddressModelLike) {
    const aux = {
      line1: address?.street,
      line2: address?.addressNumber,
      line3: address?.complement,
      neighborhood: address?.neighborhood,
      city: address?.city,
      state: address?.uf,
      zipCode: address?.zipCode,
      countryCode: address?.countryCode ?? defaultCountryCode,
    };
    return aux;
  }
}
