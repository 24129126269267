import { BaseModel } from './base.model';

export interface AuctionLotModelLike {
  id?: string;
  loteCode?: number;
  loteNumber?: number;
  auctionId?: string;
  name?: string;
  created?: Date;
  sellerName?: string;
  status?: string;
}

export class AuctionLotModel
  extends BaseModel<AuctionLotModelLike, AuctionLotModel>
  implements AuctionLotModelLike
{
  id?: string;
  loteCode?: number;
  loteNumber?: number;
  auctionId?: string;
  name?: string;
  created?: Date;
  sellerName?: string;
  status?: string;

  apply(item: Partial<AuctionLotModelLike>) {
    this.id = item?.id;
    this.loteCode = item?.loteCode;
    this.loteNumber = item?.loteNumber;
    this.auctionId = item?.auctionId;
    this.name = item?.name;
    this.created = this.parseDateTime(item?.created);
    this.sellerName = item?.sellerName;
    this.status = item?.status;
  }
}
