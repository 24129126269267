import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Data,
  Router,
  RouterStateSnapshot,
  UrlSegmentGroup,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.getAuthInfo().pipe(
      map((authInfo) => {
        const authorized = authInfo?.isAuthenticated() ?? false;
        const isBuyer = authInfo?.isBuyer ?? false;
        const isSeller = authInfo?.isSeller ?? false;

        let routeAux = route;
        while (routeAux.firstChild) {
          routeAux = routeAux.firstChild;
        }

        const data: Data = routeAux?.data ?? route.data;
        const onlyNotLogged = !!data?.onlyNotLogged;
        const onlyLogged = !!data?.onlyLogged;
        const onlyBuyer = !!data?.onlyBuyer;
        const onlySeller = !!data?.onlySeller;

        const defaultRedirectTo = 'home';

        let redirectTo!: string;
        if (onlyNotLogged && authorized) {
          redirectTo = defaultRedirectTo;
        } else if (onlyLogged && !authorized) {
          redirectTo = 'auth/login';
        } else if (authorized) {
          if (
            (onlyBuyer && onlySeller && !(isBuyer || isSeller)) ||
            (onlyBuyer && !isBuyer) ||
            (onlySeller && !isSeller)
          ) {
            redirectTo = defaultRedirectTo;
          }
        }

        if (redirectTo) {
          const queryParams: any = {};
          if (redirectTo.includes('auth')) {
            const urlSegments = routeAux.pathFromRoot.flatMap((r) => r.url);
            const urlTree = new UrlTree();
            urlTree.root = new UrlSegmentGroup(urlSegments, {});
            urlTree.queryParams = routeAux.queryParams;

            const continueUrl = this.router.serializeUrl(urlTree);

            queryParams.continueUrl = continueUrl;
          }

          this.router.navigate([redirectTo], {
            queryParams,
          });
        }

        return !redirectTo;
      })
    );
  }
}
