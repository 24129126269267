import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadOptionsParamsLike } from '../helpers/query-params/load-options-params';
import { AuctionStatusModelLike } from '../models/auction-status.model';
import { PaginationResult } from '../models/pagination-result.model';
import { LoadOptionsUtils } from '../utils/loadoptions.utils';
import { CacheFactoryService, ICacheService } from './cache-factory.service';

@Injectable()
export class AuctionStatusService {
  private cacheService: ICacheService;

  constructor(private http: HttpClient, cacheFactory: CacheFactoryService) {
    this.cacheService = cacheFactory.getOrCreate('auction-status');
  }

  query(
    options?: LoadOptionsParamsLike
  ): Observable<PaginationResult<AuctionStatusModelLike>> {
    return this.cacheService
      .getCached({
        path: 'status-list',
        source: () => {
          return this.http.get<AuctionStatusModelLike[]>(
            `~api/domain/Auction/Status`
          );
        },
      })
      .pipe(
        map((data) => {
          const res = LoadOptionsUtils.loadData(data, options);
          return res;
        })
      );
  }

  getByCode(code: string): Observable<AuctionStatusModelLike> {
    return this.query({ filter: ['code', '=', code], take: 1 }).pipe(
      map((res) => res?.data?.[0])
    );
  }
}
