import { AuctionLotStatusEnum } from './auction-lot-status.enum';
import { BaseModel } from './base.model';

export interface AuctionLotThrowModelLike {
  userId?: string;
  city?: string;
  date?: Date;
  parcela?: number;
  price?: number;
}

export interface AuctionLotEventModelLike {
  auctionId?: string;
  auctionLoteId?: string;
  bids?: number;
  dateEnd?: Date;
  priceAmount?: number;
  lance?: AuctionLotThrowModelLike;
  status?: AuctionLotStatusEnum;
}

export class AuctionLotEventModel
  extends BaseModel<AuctionLotEventModelLike, AuctionLotEventModel>
  implements AuctionLotEventModelLike
{
  auctionId?: string;
  auctionLoteId?: string;
  bids?: number;
  dateEnd?: Date;
  priceAmount?: number;
  lance?: AuctionLotThrowModelLike;
  status?: AuctionLotStatusEnum;

  apply(item: Partial<AuctionLotEventModelLike>) {
    this.auctionId = item?.auctionId;
    this.auctionLoteId = item?.auctionLoteId;
    this.bids = item?.bids;
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.priceAmount = item?.priceAmount;
    this.lance = item?.lance;
    this.status = item?.status;
  }
}
