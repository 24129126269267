import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import merge from 'lodash/merge';
import defaults from 'lodash/defaults';
import {
  animationFrameScheduler,
  asapScheduler,
  asyncScheduler,
  EMPTY,
  from,
  fromEvent,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  timer,
  zip,
} from 'rxjs';
import {
  delay,
  map,
  mergeMap,
  observeOn,
  shareReplay,
  tap,
} from 'rxjs/operators';
import Toast from 'bootstrap/js/dist/toast';

export interface ToastOptions {
  type?: 'info' | 'success' | 'warning' | 'error';
  title?: string;
  message?: string;
  autoDismiss?: boolean;
  autoDismissDuration?: 'fast' | 'medium' | 'slow';
}

export interface IToastInstance {
  dismiss(): void;
  dismissIn(ms: number): Subscription;
}

export type ToastInstance = IToastInstance;

type ToastEvent = {
  fn: (container: Element) => void;
  closed: boolean;
};

@Injectable()
export class ToastService implements OnDestroy {
  private toastSubject: Subject<ToastEvent>;
  private toastSubscription: Subscription;

  constructor(private translateService: TranslateService) {
    this.toastSubject = new ReplaySubject<ToastEvent>(1);
    this.toastSubscription = this.toastSubject
      .pipe(
        observeOn(asyncScheduler),
        mergeMap((e) => {
          if (e.closed) {
            return EMPTY;
          }

          return from(getOrCreateContainer()).pipe(
            delay(10),
            observeOn(animationFrameScheduler),
            tap((container) => {
              if (e.closed) {
                return;
              }

              e.fn(container);
            }),
            delay(10)
          );
        }, 1)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.toastSubscription?.unsubscribe();
  }

  info(message: string): ToastInstance;
  info(title: string, message: string): ToastInstance;
  info(titleOrOptions: string | ToastOptions): ToastInstance;
  info(titleOrOptions: string | ToastOptions, message?: string): ToastInstance {
    return this._toast(
      { type: 'info', title: 'defaults.toast.titles.info', autoDismiss: false },
      titleOrOptions,
      message
    );
  }

  success(message: string): ToastInstance;
  success(title: string, message: string): ToastInstance;
  success(titleOrOptions: string | ToastOptions): ToastInstance;
  success(
    titleOrOptions: string | ToastOptions,
    message?: string
  ): ToastInstance {
    return this._toast(
      {
        type: 'success',
        title: 'defaults.toast.titles.success',
      },
      titleOrOptions,
      message
    );
  }

  warning(message: string): ToastInstance;
  warning(title: string, message: string): ToastInstance;
  warning(titleOrOptions: string | ToastOptions): ToastInstance;
  warning(
    titleOrOptions: string | ToastOptions,
    message?: string
  ): ToastInstance {
    return this._toast(
      { type: 'warning', title: 'defaults.toast.titles.warning' },
      titleOrOptions,
      message
    );
  }

  error(message: string): ToastInstance;
  error(title: string, message: string): ToastInstance;
  error(titleOrOptions: string | ToastOptions): ToastInstance;
  error(
    titleOrOptions: string | ToastOptions,
    message?: string
  ): ToastInstance {
    return this._toast(
      {
        type: 'error',
        title: 'defaults.toast.titles.error',
        autoDismissDuration: 'slow',
      },
      titleOrOptions,
      message
    );
  }

  toast(options?: ToastOptions): ToastInstance {
    return this._toast(options);
  }

  private _toast(
    defaultOptions?: ToastOptions,
    titleOrOptions?: string | ToastOptions,
    message?: string
  ): ToastInstance {
    defaultOptions = defaultOptions ?? {};
    const type = defaultOptions.type;
    const titleDefault = defaultOptions.title;
    let title =
      typeof titleOrOptions === 'string'
        ? titleOrOptions
        : titleOrOptions?.title ?? titleDefault;
    if (typeof message !== 'string') {
      message =
        typeof titleOrOptions !== 'string'
          ? titleOrOptions?.message ?? title
          : title;
      title = titleDefault;
    }

    if (typeof titleOrOptions === 'string') {
      titleOrOptions = merge({}, defaultOptions, { title, message, type });
    } else {
      titleOrOptions = merge({}, defaultOptions, titleOrOptions, {
        title,
        message,
        type,
      });
    }

    titleOrOptions = defaults<ToastOptions, ToastOptions>(titleOrOptions, {
      autoDismiss: true,
      autoDismissDuration: 'fast',
    });
    return new ToastInstanceImpl(
      this.toastSubject,
      this.translateService,
      titleOrOptions
    );
  }
}

function handleLineSeparators(text: string) {
  return (
    text
      ?.replace(new RegExp('\n\r', 'g'), '<br />')
      ?.replace(new RegExp('\r\n', 'g'), '<br />')
      ?.replace(new RegExp('\r', 'g'), '<br />')
      ?.replace(new RegExp('\n', 'g'), '<br />') ?? ''
  );
}

class ToastInstanceImpl implements IToastInstance {
  private subscription: Subscription;

  constructor(
    private toastSubject: Subject<ToastEvent>,
    translateService: TranslateService,
    options?: ToastOptions
  ) {
    let { message, title, autoDismiss, autoDismissDuration, type } =
      options ?? {};

    this.subscription = new Subscription();

    const translationsObservables: Observable<string>[] = [
      !message ? of('') : translateService.get(message),
    ];
    if (title) {
      translationsObservables.push(translateService.get(title));
    }

    const subscriptionRef = new WeakRef(this.subscription);
    this.subscription.add(
      zip(...translationsObservables)
        .pipe(observeOn(asapScheduler))
        .subscribe(([message, title]) => {
          message = handleLineSeparators(message!);

          let color: string;
          switch (type) {
            case 'success':
              color = 'var(--base-success)';
              break;
            case 'warning':
              color = 'var(--base-warning)';
              break;
            case 'error':
              color = 'var(--base-danger)';
              break;
            case 'info':
            default:
              color = 'var(--base-info)';
              break;
          }

          let html = [
            `<div class="toast">`,
            title
              ? `<div class="toast-header">
              <svg class="toast-indicator rounded me-2" width="20" height="20"
              xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
              <rect width="100%" height="100%" fill="${color}"></rect>
              </svg>
              <span class="me-auto">${title}</span>
              <button type="button" class="btn-close pointer" data-bs-dismiss="toast" aria-label="Close"></button>
              </div>`
              : '',
            `<div class="toast-body">${message}</div>`,
            `</div>`,
          ]
            .map((x) => x?.trim())
            .filter((x) => !!x)
            .join('');

          let delay: number;
          switch (autoDismissDuration) {
            case 'medium':
              delay = 3000;
              break;
            case 'slow':
              delay = 4000;
              break;
            case 'fast':
            default:
              delay = 2000;
              break;
          }

          this.toastSubject.next({
            get closed() {
              return subscriptionRef.deref()?.closed;
            },
            fn: (container) => {
              const elTemp = document.createElement('div');
              elTemp.innerHTML = html;
              const toastEl = elTemp.firstChild! as Element;
              container.append(elTemp.firstChild!);

              const toast = new Toast(toastEl, {
                animation: true,
                autohide: autoDismiss,
                delay,
              });
              toast.show();

              subscriptionRef.deref()?.add(
                fromEvent(toastEl, 'hidden.bs.toast').subscribe((e) => {
                  subscriptionRef.deref()?.unsubscribe();
                })
              );
              subscriptionRef.deref()?.add(() => {
                requestAnimationFrame(() => {
                  toast.dispose();
                });
              });
            },
          });
        })
    );
  }

  dismiss(): void {
    this.subscription?.unsubscribe();
  }

  dismissIn(ms: number) {
    const subscription = asyncScheduler.schedule(() => {
      this.dismiss();
    }, ms);
    this.subscription?.add(subscription);
    return subscription;
  }
}

let containerPromise: Promise<Element>;
function getOrCreateContainer(): Promise<Element> {
  if (!containerPromise) {
    containerPromise = new Promise((resolve) => {
      requestAnimationFrame(() => {
        const classAux = 'toast-container';
        const elements = document.getElementsByClassName(classAux) ?? [];
        let element = elements[0];
        if (!element) {
          element = document.createElement('div');
          element.classList.add(classAux);
          document.getElementsByTagName('body')[0].append(element);
        }

        resolve(element);
      });
    });
  }

  return containerPromise;
}
