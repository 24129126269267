import { AbstractParams } from './abstract-params';
import { HttpParams } from '@angular/common/http';

export interface PageParamsLike {
  skip?: number;
  take?: number;
  requireTotalCount?: boolean;

  skipOptional?: number;
  takeOptional?: number;
  requireTotalCountOptional?: boolean;
}

export class PageParams<
    T extends PageParams = PageParams<any, any>,
    I extends PageParamsLike = PageParamsLike
  >
  extends AbstractParams<T, I>
  implements PageParamsLike {
  skip?: number;
  take?: number;
  requireTotalCount?: boolean;

  skipOptional?: number;
  takeOptional?: number;
  requireTotalCountOptional?: boolean;

  setSkip(skip: number) {
    this.skip = skip;
    return this;
  }

  setTake(take: number) {
    this.take = take;
    return this;
  }

  setRequireTotalCount(requireTotalCount: boolean) {
    this.requireTotalCount = requireTotalCount;
    return this;
  }

  setSkipOptional(skipOptional: number) {
    this.skipOptional = skipOptional;
    return this;
  }

  setTakeOptional(takeOptional: number) {
    this.takeOptional = takeOptional;
    return this;
  }

  setRequireTotalCountOptional(requireTotalCountOptional: boolean) {
    this.requireTotalCountOptional = requireTotalCountOptional;
    return this;
  }

  getSkip() {
    return this.skip ?? this.skipOptional;
  }

  getTake() {
    return this.take ?? this.takeOptional;
  }

  getRequireTotalCount() {
    return this.requireTotalCount ?? this.requireTotalCountOptional;
  }

  apply(options?: Partial<PageParamsLike>): void {
    this.skip = options?.skip;
    this.take = options?.take;
    this.requireTotalCount = options?.requireTotalCount;

    this.skipOptional = options?.skipOptional;
    this.takeOptional = options?.takeOptional;
    this.requireTotalCountOptional = options?.requireTotalCountOptional;
  }

  toHttpParams(): HttpParams {
    let params = new HttpParams();
    const skip = this.getSkip();
    const take = this.getTake();
    const requireTotalCount = this.getRequireTotalCount();
    if (skip) {
      params = params.append('skip', String(skip));
    }
    if (take) {
      params = params.append('take', String(take));
    }
    if (requireTotalCount) {
      params = params.append('requireTotalCount', String(requireTotalCount));
    }
    return params;
  }
}
