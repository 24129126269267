import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AuctionRegisterModel } from '../models/auction-register.model';
import { AuctionService } from '../services/auction.service';

@Injectable()
export class AuctionChangeResolver implements Resolve<AuctionRegisterModel> {
  constructor(private auctionService: AuctionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AuctionRegisterModel> {
    return this.auctionService.getAuctionForChangeById(route.params.auctionId);
  }
}
