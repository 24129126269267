import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuctionRunningDetailModel } from '../models/auction-running-detail.model';
import { AuctionService } from '../services/auction.service';

@Injectable()
export class AuctionDetailRunningResolver
  implements Resolve<AuctionRunningDetailModel>
{
  constructor(private auctionService: AuctionService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<AuctionRunningDetailModel> {
    return this.auctionService.getAuctionRunningDetailById(
      route.params.auctionId
    );
  }
}
