import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { dxPopupOptions } from 'devextreme/ui/popup';
import { Observable, Subject, zip } from 'rxjs';
import { defaultIfEmpty, map, switchMap, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-user-menu-panel',
  templateUrl: 'user-menu-panel.component.html',
  styleUrls: ['user-menu-panel.component.scss'],
})
export class UserMenuPanelComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('btnContainer')
  btnContainerEl: ElementRef;

  userNameObservable: Observable<string>;

  authorized: boolean;

  private defaultMenuItems = [
    {
      text: 'user-menu-panel.menu-items.logout',
      icon: 'mdi mdi-logout-variant',
      onClick: () => {
        this.authService.logOut();
      },
    },
  ];

  private settingsMenuItems = [
    {
      text: 'user-menu-panel.menu-items.settings',
      icon: 'mdi mdi-account-box-outline',
      onClick: () => {
        this.router.navigate(['/']);
      },
    },
  ];

  private sellerMenuItems = [
    {
      text: 'user-menu-panel.menu-items.my-auction-list',
      icon: 'assets/Auction hammer.svg',
      onClick: () => {
        this.router.navigate(['/my-auction-list']);
      },
    },
  ];

  private myLotesInAuctionMenuItems = [
    {
      text: 'user-menu-panel.menu-items.myLotesInAuction',
      icon: 'mdi mdi-ballot-outline',
      onClick: () => {
        this.router.navigate(['/']);
      },
    },
  ];

  private myLotesSoldOutMenuItems = [
    {
      text: 'user-menu-panel.menu-items.myLotesSoldOut',
      icon: 'mdi mdi-currency-usd',
      onClick: () => {
        this.router.navigate(['/']);
      },
    },
  ];

  private buyerMenuItems = [
    {
      text: 'user-menu-panel.menu-items.my-card-list',
      icon: 'mdi mdi-credit-card-outline',
      onClick: () => {
        this.router.navigate(['/my-card-list']);
      },
    },
  ];

  private myThrowRunningMenuItems = [
    {
      text: 'user-menu-panel.menu-items.my-throw-running',
      icon: 'assets/Auction hammer.svg',
      onClick: () => {
        this.router.navigate(['/my-throws']);
      },
    },
  ];

  private myShoppingMenuItems = [
    {
      text: 'user-menu-panel.menu-items.my-shopping',
      icon: 'mdi mdi-cart-minus',
      onClick: () => {
        this.router.navigate(['/my-shopping']);
      },
    },
  ];

  menuItems = [];

  dropDownOptions: dxPopupOptions;

  private lifecycle = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userNameObservable = this.authService.userNameObservable();
    this.authService
      .observeAuthenticated()
      .pipe(takeUntil(this.lifecycle))
      .subscribe((authorized) => {
        this.authorized = authorized;
      });

    this.authService
      .observeAuthInfo()
      .pipe(
        map((authInfo) => {
          let menuItems = [];

          if (authInfo?.isBuyer) {
            menuItems = menuItems.concat(this.settingsMenuItems);
            menuItems = menuItems.concat(this.buyerMenuItems);
            menuItems = menuItems.concat(this.myThrowRunningMenuItems);
            menuItems = menuItems.concat(this.myShoppingMenuItems);
          }

          if (authInfo?.isSeller) {
            menuItems = menuItems.concat(this.settingsMenuItems);
            menuItems = menuItems.concat(this.sellerMenuItems);
            menuItems = menuItems.concat(this.myLotesInAuctionMenuItems);
            menuItems = menuItems.concat(this.myLotesSoldOutMenuItems);
             
          }

          menuItems = menuItems.concat(this.defaultMenuItems);

          return menuItems;
        }),
        switchMap((menuItems) => {
          const menuItemsObservables = menuItems.map((item) => {
            return this.translateService.get(item.text).pipe(
              map((text) => {
                return {
                  ...item,
                  text,
                };
              })
            );
          });

          return zip(...menuItemsObservables).pipe(defaultIfEmpty([]));
        }),
        takeUntil(this.lifecycle)
      )
      .subscribe((menuItems) => {
        this.menuItems = menuItems;
      });
  }

  ngAfterViewInit() {
    if (this.btnContainerEl?.nativeElement) {
      this.dropDownOptions = {
        animation: {
          show: { type: 'fadeIn', duration: 300 },
          hide: { type: 'fadeOut', duration: 300 },
        },
        position: {
          of: this.btnContainerEl.nativeElement,
          my: 'right top',
          at: 'right bottom',
        },
      };
      this.changeDetector.detectChanges();
    }
  }

  ngOnDestroy() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }
}
