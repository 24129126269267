<ng-container [ngSwitch]="layoutMode">
  <ng-container *ngSwitchCase="layoutModeEnum.Auth">
    <app-auth-layout *ngSwitchCase="layoutModeEnum.Auth" [bgPath]="bgPath">
      <router-outlet></router-outlet>
    </app-auth-layout>
  </ng-container>

  <ng-container *ngSwitchCase="layoutModeEnum.Default">
    <app-default-layout>
      <router-outlet></router-outlet>
    </app-default-layout>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <router-outlet></router-outlet>
  </ng-container>
</ng-container>

<app-load-panel [visible]="loadingVisible"></app-load-panel>
