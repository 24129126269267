import { HttpParams } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { ICloneable } from '../interfaces';

export abstract class AbstractParams<T, I> implements ICloneable<T> {
  constructor(options?: Partial<I>) {
    this.apply(options);
  }

  clone(): T {
    return cloneDeep(this) as any;
  }

  abstract apply(options?: Partial<I>): void;

  abstract toHttpParams(): HttpParams;
}
