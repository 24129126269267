import { BaseModel } from './base.model';

export interface AuctionModelLike {
  id?: string;
  name?: string;
  status?: string;
  image?: string;
  dateStart?: Date;
  dateEnd?: Date;
  created?: Date;
  throwsCount?: number;
  numberLotes?: number;
}

export class AuctionModel
  extends BaseModel<AuctionModelLike, AuctionModel>
  implements AuctionModelLike {
  id?: string;
  name?: string;
  status?: string;
  image?: string;
  dateStart?: Date;
  dateEnd?: Date;
  created?: Date;
  throwsCount?: number;
  numberLotes?: number;

  apply(item: Partial<AuctionModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.status = item?.status;
    this.image = item?.image;
    this.dateStart = this.parseDateTime(item?.dateStart);
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.created = this.parseDateTime(item?.created);
    this.throwsCount = item?.throwsCount;
    this.numberLotes = item?.numberLotes;
  }
}
