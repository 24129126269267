import { BaseModel } from './base.model';

export interface AuctionEventModelLike {
  auctionId?: string;
  bids?: number;
  numberLotes?: number;
  dateEnd?: Date;
}

export class AuctionEventModel
  extends BaseModel<AuctionEventModelLike, AuctionEventModel>
  implements AuctionEventModelLike
{
  auctionId?: string;
  bids?: number;
  numberLotes?: number;
  dateEnd?: Date;

  apply(item: Partial<AuctionEventModelLike>) {
    this.auctionId = item?.auctionId;
    this.bids = item?.bids;
    this.numberLotes = item?.numberLotes;
    this.dateEnd = this.parseDateTime(item?.dateEnd);
  }
}
