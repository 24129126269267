<div class="container">
  <div class="row justify-content-between">
    <div class="col-auto">
      <span>by ZUKK</span>
    </div>
    <div class="col-auto">
      <span> {{ appInfo.appName }} @ - {{ appInfo.currentYear }} </span>
    </div>
    <div class="col-auto">
      <div class="row">
        <div class="col-auto" *ngFor="let item of appInfo.socialMediaItems">
          <a class="social-media-link" [href]="item.link" target="_blank">
            <app-iconic class="icon" [icon]="item.icon"></app-iconic>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
