import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Data,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { animationFrameScheduler, asyncScheduler, Subject } from 'rxjs';
import { LayoutModeEnum } from './shared/models/layout-mode.model';
import { AuthService } from './shared/services/auth.service';
import { GlobalLoadingService } from './shared/services/global-loading.service';

import { first, observeOn, skip, takeUntil } from 'rxjs/operators';
import { ToastService } from './shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { CacheFactoryService } from './shared/services/cache-factory.service';

import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

import ptMessages from 'devextreme/localization/messages/pt.json';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loadingVisible = false;

  layoutModeEnum = LayoutModeEnum;
  layoutMode = LayoutModeEnum.None;

  bgPath: string;

  private lifecycle = new Subject();

  constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: GlobalLoadingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private cacheFactoryService: CacheFactoryService
  ) {
    registerLocaleData(localePt, 'pt');

    loadMessages(ptMessages);
    config({ defaultCurrency: 'BRL', editorStylingMode: 'outlined' });
  }

  ngOnInit() {
    this.updateLang(this.translateService.currentLang);

    this.authService
      .observeAuthenticated()
      .pipe(skip(1), takeUntil(this.lifecycle))
      .subscribe((value) => {
        if (!value) {
          this.router.navigate(['/auth']);
          this.cacheFactoryService.clearAllCaches();
        }
      });

    this.loadingService.loadingChanges
      .pipe(observeOn(animationFrameScheduler), takeUntil(this.lifecycle))
      .subscribe((visible) => {
        this.loadingVisible = visible;
      });

    this.router.events.pipe(takeUntil(this.lifecycle)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loadingService.showLoading();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this.loadingService.hideLoading();

        if (event instanceof NavigationEnd) {
          let route = this.router.routerState.root;
          let data: Data = Object.assign({}, route?.snapshot?.data ?? {});
          if (route) {
            while (route.firstChild) {
              route = route.firstChild;
              Object.assign(data, route?.snapshot?.data ?? {});
            }
          }

          this.layoutMode = data.layoutMode ?? LayoutModeEnum.Default;
          this.bgPath = data.bgPath ?? '';
        } else if (event instanceof NavigationError) {
          this.toastService.error('defaults.errors.router');
        }
      }
    });
  }

  ngOnDestroy() {
    this.lifecycle.next();
    this.lifecycle.complete();
  }

  private updateLang(lang: string) {
    locale(lang);
  }
}
