import { BaseModel } from './base.model';

export interface CardModelLike {
  id?: string;
  holderName?: string;
  expirationMonth?: string;
  expirationYear?: string;
  cardNumber?: string;
  cardBrand?: string;
}

export class CardModel
  extends BaseModel<CardModelLike, CardModel>
  implements CardModelLike {
  id?: string;
  holderName?: string;
  expirationMonth?: string;
  expirationYear?: string;
  cardNumber?: string;
  cardBrand?: string;

  apply(item: Partial<CardModelLike>) {
    this.id = item?.id;
    this.holderName = item?.holderName;
    this.expirationMonth = item?.expirationMonth;
    this.expirationYear = item?.expirationYear;
    this.cardNumber = item?.cardNumber;
    this.cardBrand = item?.cardBrand;
  }
}

