import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpEvent,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of, asapScheduler } from 'rxjs';
import {
  tap,
  flatMap,
  map,
  subscribeOn,
  observeOn,
  catchError,
  mergeMap,
} from 'rxjs/operators';
import { AppInfoService } from '../services/app-info.service';
import { AuthService } from '../services/auth.service';
import { HttpStatus, HttpUtils } from '../utils/http.utils';
import { ToastService } from '../services/toast.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private appInfoService: AppInfoService,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const url = this.handleUrl(req.url);
    let observable: Observable<HttpRequest<any>>;
    if (!this.appInfoService.isApiUrl(url)) {
      observable = of(
        req.clone({
          url,
        })
      );
    } else {
      observable = this.authService.getToken().pipe(
        map((token) => {
          let newRequest: HttpRequest<any>;
          if (token) {
            newRequest = req.clone({
              url,
              setHeaders: {
                Authorization: 'Bearer ' + token,
              },
            });
          } else {
            newRequest = req.clone({
              url,
            });
          }

          return newRequest;
        })
      );
    }
    return observable.pipe(
      mergeMap((newRequest) => {
        return next.handle(newRequest).pipe(
          observeOn(asapScheduler),
          mergeMap((response) => {
            if (
              response instanceof HttpResponse &&
              response.body &&
              response.body.error
            ) {
              return throwError(HttpUtils.createBadRequest());
            }
            return of(response);
          }),
          catchError((response) => {
            if (response instanceof HttpErrorResponse) {
              switch (response.status) {
                case 0:
                  this.toastService.error(
                    'defaults.errors.http.cannotAccessServer'
                  );
                  response = false;
                  break;
                case HttpStatus.UNAUTHORIZED:
                  this.authService.logOut();
                  response = false;
                  break;
              }
            }

            return throwError(response);
          })
        );
      }),
      subscribeOn(asapScheduler),
      observeOn(asapScheduler)
    );
  }

  handleUrl(url: string) {
    return this.appInfoService.normalizeUrl(url);
  }
}
