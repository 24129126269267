import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconicComponent } from './iconic.component';
import { SvgIconModule } from '../svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, SvgIconModule],
  declarations: [IconicComponent],
  exports: [IconicComponent],
})
export class IconicModule {}
