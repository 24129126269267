import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';

export enum CountTimerEvent {
  Started = 1,
  Resumed = 2,
  Counted = 3,
  Paused = 4,
  Stoped = 5,
}

export class CountdownTimed {
  constructor(
    public readonly time: number,
    public readonly type: CountTimerEvent
  ) {}
}

export class RxCountdownTimer {
  private _timerSubject: Subject<CountdownTimed>;

  private _subscription: Subscription;

  private _currentTime: number;

  startTime: number;
  stopTime: number;
  frequency: number;

  public isStarted = false;
  public isResumed = false;
  public isCounting = false;
  public isPaused = false;
  public isStopped = false;
  public isDestroyed = false;

  get events() {
    return this._timerSubject.asObservable();
  }

  constructor(
    startTime: number | Date,
    stopTime?: number | Date,
    frequency?: number
  ) {
    this._timerSubject = new Subject();
    this._setup(startTime, stopTime, frequency);
  }

  reset(
    startTime: number | Date,
    stopTime?: number | Date,
    frequency?: number
  ) {
    this.stop();

    this._setup(
      startTime ?? this.startTime,
      stopTime ?? this.stopTime,
      frequency ?? this.frequency
    );
  }

  start() {
    if (this.isDestroyed) {
      return;
    }

    if (this.isStarted) {
      return;
    }

    this.isStopped = false;
    this.isPaused = false;
    this.isResumed = false;
    this.isStarted = true;
    this._currentTime = this.startTime;
    this._emitEvent(CountTimerEvent.Started);
    this.resume();
  }

  resume() {
    if (this.isDestroyed) {
      return;
    }

    if (
      this._currentTime < this.stopTime ||
      !this.isStarted ||
      this.isResumed
    ) {
      return;
    }

    this.isStopped = false;
    this.isPaused = false;
    this.isResumed = true;
    this._reflow();
    this._emitEvent(CountTimerEvent.Resumed);
  }

  pause() {
    if (this.isDestroyed) {
      return;
    }

    if (this.isPaused || this.isStopped || !this.isResumed) {
      return;
    }

    this.isResumed = false;
    this.isPaused = true;
    this._subscription?.unsubscribe();
    this._emitEvent(CountTimerEvent.Paused);
  }

  stop() {
    if (this.isDestroyed) {
      return;
    }

    if (
      this._currentTime < this.stopTime ||
      this.isStopped ||
      !this.isStarted
    ) {
      return;
    }

    this.pause();

    this.isStarted = false;
    this.isStopped = true;
    this._currentTime = this.startTime;
    this._emitEvent(CountTimerEvent.Stoped);
  }

  destroy() {
    if (this.isDestroyed) {
      return;
    }

    this.isDestroyed = true;
    this._subscription?.unsubscribe();
    this._timerSubject.complete();
  }

  private _setup(
    startTime: number | Date,
    stopTime: number | Date,
    frequency: number
  ) {
    if (startTime instanceof Date) {
      this.startTime = startTime.getTime() - Date.now();
    } else {
      this.startTime = startTime ?? 0;
    }

    if (stopTime instanceof Date) {
      this.stopTime = stopTime.getTime();
    } else {
      this.stopTime = stopTime ?? 0;
    }

    this.frequency = frequency ?? 1000;
  }

  private _reflow() {
    const thisRef = new WeakRef(this);
    const frequency = this.frequency;

    const subscription = new Observable((subscriber) => {
      const thisAux = thisRef.deref();

      let lastTime = Date.now();
      const intervalId = setInterval(() => {
        const now = Date.now();
        const diff = now - lastTime;
        lastTime = now;
        thisAux._currentTime -= diff;
        if (thisAux._currentTime < thisAux.stopTime) {
          thisAux._currentTime = thisAux.stopTime;
          subscriber.complete();
        }

        thisAux._emitEvent(CountTimerEvent.Counted);
      }, frequency);

      subscriber.add(() => {
        clearInterval(intervalId);
      });
    }).subscribe();

    this._subscription = subscription;
  }

  private _emitEvent(eventType: CountTimerEvent) {
    this._timerSubject.next(new CountdownTimed(this._currentTime, eventType));
  }
}

@Injectable()
export class CountdownService {
  createCountTimer(startTime: number | Date, stopTime?: number | Date) {
    return new RxCountdownTimer(startTime, stopTime, 1000);
  }
}
