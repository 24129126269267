import { InstallmentModelLike } from './auction-lot-running.model';
import { AuctionLotStatusEnum } from './auction-lot-status.enum';
import { BaseModel } from './base.model';
import { BreedModelLike } from './breed.model';

export interface LotBetModelLike {
  userId?: string;
  parcela?: number;
  price?: number;
  date?: Date;
}

export interface AuctionLotThrowRunningModelLike {
  loteId?: string;
  auctionId?: string;
  name?: string;
  description?: string;
  breed?: BreedModelLike;
  gender?: string;
  status?: AuctionLotStatusEnum;
  incrementMin?: number[];
  bids?: number;
  parcelasCount?: number;
  priceAmount?: number;
  parcela?: InstallmentModelLike;
  lastBet?: LotBetModelLike;
  myLastBet?: LotBetModelLike;
  imageUrl?: string;
  dateEnd?: Date;
  birthDay?: Date;
}

export class AuctionLotThrowRunningModel
  extends BaseModel<AuctionLotThrowRunningModelLike, AuctionLotThrowRunningModel>
  implements AuctionLotThrowRunningModelLike
{
  loteId?: string;
  auctionId?: string;
  name?: string;
  description?: string;
  breed?: BreedModelLike;
  gender?: string;
  status?: AuctionLotStatusEnum;
  incrementMin?: number[];
  bids?: number;
  parcelasCount?: number;
  priceAmount?: number;
  parcela?: InstallmentModelLike;
  lastBet?: LotBetModelLike;
  myLastBet?: LotBetModelLike;
  imageUrl?: string;
  dateEnd?: Date;
  birthDay?: Date;

  get isSold() {
    return this.status === AuctionLotStatusEnum.Sold;
  }

  apply(item: Partial<AuctionLotThrowRunningModelLike>) {
    this.loteId = item?.loteId;
    this.auctionId = item?.auctionId;
    this.name = item?.name;
    this.description = item?.description;
    this.breed = item?.breed;
    this.gender = item?.gender;
    this.status = item?.status;
    this.incrementMin = item?.incrementMin;
    this.bids = item?.bids;
    this.parcelasCount = item?.parcelasCount;
    this.priceAmount = item?.priceAmount;
    this.parcela = item?.parcela;
    this.lastBet = item?.lastBet;
    this.myLastBet = item?.myLastBet;
    this.imageUrl = item?.imageUrl;
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.birthDay = this.parseDateTime(item?.birthDay);
  }
}
