import isObject from 'lodash/isObject';
import isObjectLike from 'lodash/isObjectLike';
import isEqualWith from 'lodash/isEqualWith';
import isUndefined from 'lodash/isUndefined';
import isFunction from 'lodash/isFunction';
import { Type } from '@angular/core';

const equalsInternal = (a, b) => {
  const aIsObject = isObject(a);
  if (aIsObject && typeof a.equals === 'function') {
    return a.equals(b);
  }
  const bIsObject = isObject(b);
  if (bIsObject && typeof b.equals === 'function') {
    return b.equals(a);
  }

  return a === b || (aIsObject && bIsObject && a.valueOf() === b.valueOf());
};

export class ObjectUtils {
  static isEmpty(value) {
    return value === undefined || value === null || value === '';
  }

  static equals(a, b): boolean {
    if (a === b) {
      return true;
    }

    return isEqualWith(a, b, (a2, b2) => {
      if (a2 !== a || b2 !== b) {
        return equalsInternal(a2, b2);
      }
    });
  }

  static hasAnyFieldDifferent<T>(a: T, b: T, keys: Array<keyof T>) {
    for (const key of keys) {
      if (!ObjectUtils.equals(a[key], b[key])) {
        return true;
      }
    }
    return false;
  }

  static plain<T = any>(item: T): any {
    return this.recursiveMethodKeys(item, 'plain');
  }

  static toJSON<T = any>(item: T): any {
    return this.recursiveMethodKeys(item, 'toJSON');
  }

  static getGettersKeysFrom(obj: any) {
    const proto = Object.getPrototypeOf(obj);
    const descriptors = Object.getOwnPropertyDescriptors(proto);
    const descriptorsKeys = Object.keys(descriptors);
    const size = descriptorsKeys.length;
    let realSize = 0;
    let getters = new Array<string>(size);
    let key: string;
    let descriptor: any;
    for (let i = 0; i < size; ++i) {
      key = descriptorsKeys[i];
      descriptor = descriptors[key];
      if (descriptor && typeof descriptor.get === 'function') {
        getters[i] = key;
        ++realSize;
      }
    }

    if (realSize < size) {
      const newGetters = new Array<string>(realSize);
      let j = -1;
      for (let i = 0; i < size; ++i) {
        key = getters[i];
        if (key) {
          newGetters[++j] = key;
        }
      }
      getters = newGetters;
    }

    return getters;
  }

  private static recursiveMethodKeys<T = any>(item: T, methodKey: string): any {
    if (Array.isArray(item)) {
      const size = item.length;
      const newArray = new Array(size);
      for (let i = 0; i < size; ++i) {
        newArray[i] = this.recursiveMethodKeys(item[i], methodKey);
      }

      return newArray;
    }
    if (isUndefined(item)) {
      return item;
    }
    if (isObjectLike(item)) {
      let itemAux: any = item;
      if (isFunction(itemAux[methodKey])) {
        return itemAux[methodKey]();
      }
      const aux: any = {};
      const keys = Object.keys(item);

      for (const key of keys) {
        itemAux = item[key];
        if (isObjectLike(itemAux)) {
          if (isFunction(itemAux[methodKey])) {
            itemAux = itemAux[methodKey]();
          } else {
            itemAux = this.recursiveMethodKeys(itemAux, methodKey);
          }
        }

        aux[key] = itemAux;
      }
    }
    return item;
  }

  static create<T>(type: Type<T>, ...args: any[]): T {
    return new type(...args);
  }
}
