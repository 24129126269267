<ng-container [ngSwitch]="iconType" *ngIf="!!iconValue">
  <svg-icon
    class="icon-instance"
    [src]="iconValue"
    *ngSwitchCase="iconTypes.Svg"
  ></svg-icon>
  <img
    class="icon-instance"
    [src]="iconValue"
    *ngSwitchCase="iconTypes.Image"
  />
  <i
    class="icon-instance {{ iconValue }}"
    *ngSwitchCase="iconTypes.Default"
  ></i>
</ng-container>
