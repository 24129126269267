import { Injectable } from '@angular/core';
import { AppConfigLike } from '../interfaces/config.interface';

interface IconLink {
  icon: string;
  link?: string;
}

export class AppInfoService {
  readonly appName = 'maisumlance';

  socialMediaItems: IconLink[] = [
    {
      icon: 'mdi mdi-facebook',
    },
    {
      icon: 'mdi mdi-instagram',
    },
    {
      icon: 'mdi mdi-twitter',
    },
    {
      icon: 'mdi mdi-linkedin',
    },
  ];

  get currentYear() {
    return new Date().getFullYear();
  }

  API: string;
  services: any;
  servicesKeys: any;

  constructor(config: AppConfigLike) {
    this.API = config.apiUrl;
    this.services = config.services;
    this.servicesKeys = Object.keys(this.services);
  }

  normalizeUrl(url: string): string {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = this.parseServiceIfNeeded(url);
    }

    url = this.normalizeUrlInternal(url);
    return url;
  }

  isApiUrl(url: string) {
    return url?.startsWith(this.API) ?? false;
  }

  private parseServiceIfNeeded(url: string) {
    const shouldParseApi = url.startsWith('~');
    if (shouldParseApi) {
      let key: string = '';
      this.servicesKeys.forEach((x: any) => {
        if (
          url.startsWith('~' + x) &&
          (!key || (key && key.length < x.length))
        ) {
          key = x;
        }
      });
      if (key) {
        url = url.replace('~' + key, this.services[key]);
      } else {
        url = url.replace('~', '');
      }
    }

    if (
      shouldParseApi &&
      !url.startsWith('http://') &&
      !url.startsWith('https://')
    ) {
      url = this.API + '/' + url;
    }

    return url;
  }

  private normalizeUrlInternal(url: string) {
    let prefix: string;
    if (url.startsWith('http://')) {
      prefix = 'http://';
    } else if (url.startsWith('https://')) {
      prefix = 'https://';
    } else {
      prefix = '';
    }
    return (
      prefix +
      url
        .substr(prefix.length)
        .replace(/(\/\/)/g, '/')
        .replace(/(\/\/\/)/g, '/')
        .replace(/(\/\/)/g, '/')
        .replace(/(\/\/\/)/g, '/')
    );
  }
}
