import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { RxLocalStorage } from '@rxjs-storage/core';
import {
  asapScheduler,
  concat,
  merge,
  Observable,
  of,
  OperatorFunction,
  Subscription,
  timer,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  flatMap,
  map,
  mergeMap,
  observeOn,
  shareReplay,
} from 'rxjs/operators';
import { storageKeys } from '../constants/storage.constant';
import { AuthInfoLike, AuthInfoModel } from '../models/auth-info.model';
import { BaseModel } from '../models/base.model';
import jwt_decode from 'jwt-decode';
import { RxBroadcastChannel } from 'rxjs-broadcast-channel';
import { AuthCreateModelLike } from '../models/auth-create.model';

const authInfoKey = storageKeys.authInfo;

@Injectable()
export class AuthService implements OnDestroy {
  private _authInfoObservable!: Observable<AuthInfoModel>;
  private _userNameObservable!: Observable<string>;
  private _authenticatedObservable!: Observable<boolean>;
  private rxBroadcastChannel: RxBroadcastChannel;

  constructor(
    private rxLocalStorage: RxLocalStorage,
    private http: HttpClient
  ) {
    this.rxBroadcastChannel = new RxBroadcastChannel(authInfoKey);
  }

  ngOnDestroy() {
    this.rxBroadcastChannel.dispose();
  }

  logIn(email: string, password: string): Observable<AuthInfoLike> {
    return this.http
      .post<any>('~auth/login', {
        email,
        senha: password,
      })
      .pipe(this.handleAuthInfoOperator());
  }

  logOut() {
    this.rxLocalStorage.removeItem(authInfoKey);
    this.rxBroadcastChannel.postMessage('logout');
  }

  forgotPassword(email: string): Observable<any> {
    return this.http.post<any>('~auth/reset-password', null, {
      params: { email },
    });
  }

  userNameObservable() {
    if (!this._userNameObservable) {
      this._userNameObservable = this.observeAuthInfo().pipe(
        this.filterIsAuthenticatedOperator(),
        map((authInfo) => authInfo?.nameUser ?? ''),
        distinctUntilChanged(),
        shareReplay(1)
      );
    }

    return this._userNameObservable;
  }

  getToken() {
    return this.getAuthInfo().pipe(map((x) => x?.token));
  }

  getAuthInfo(): Observable<AuthInfoModel> {
    return this.observeAuthInfo().pipe(first());
  }

  observeAuthenticated(): Observable<boolean> {
    if (!this._authenticatedObservable) {
      const isAuthenticatedFn = (authInfo: AuthInfoModel) => {
        return authInfo?.isAuthenticated() ?? false;
      };

      const observable = new Observable<boolean>((subscriber) => {
        let subscription: Subscription;

        subscriber.add(() => {
          subscription?.unsubscribe();
        });
        subscriber.add(
          this.observeAuthInfo().subscribe((authInfo) => {
            subscription?.unsubscribe();

            const authenticated = isAuthenticatedFn(authInfo);
            if (authenticated) {
              const delay = authInfo.expires!.getTime() - Date.now();
              subscription = timer(delay, 1000).subscribe(() => {
                if (!isAuthenticatedFn(authInfo)) {
                  subscription?.unsubscribe();
                  this.rxLocalStorage.removeItem(authInfoKey);
                }
              });
            }

            subscriber.next(authenticated);
          })
        );
      });

      this._authenticatedObservable = observable.pipe(
        distinctUntilChanged(),
        shareReplay(1)
      );
    }

    return this._authenticatedObservable;
  }

  observeAuthInfo(): Observable<AuthInfoModel> {
    if (!this._authInfoObservable) {
      const observable = new Observable<AuthInfoModel>((subscriber) => {
        const aux: AuthInfoLike = this.rxLocalStorage.getItem(authInfoKey);
        let result = BaseModel.createOrDefault(AuthInfoModel, aux);

        if (result && !result.isAuthenticated()) {
          result = null;
        }

        subscriber.next(result);
        subscriber.complete();

        if (!result && this.rxLocalStorage.hasItem(authInfoKey)) {
          this.rxLocalStorage.removeItem(authInfoKey);
        }
      });

      this._authInfoObservable = concat(
        observable,
        merge(
          this.rxLocalStorage.onItemChanged(authInfoKey),
          this.rxLocalStorage.onItemRemoved(authInfoKey),
          this.rxBroadcastChannel.events
        ).pipe(mergeMap(() => observable))
      ).pipe(
        distinctUntilChanged(),
        shareReplay(1, Number.POSITIVE_INFINITY, asapScheduler)
      );
    }
    return this._authInfoObservable;
  }

  updateAuthInfo(authInfo: AuthInfoLike): AuthInfoModel {
    if (authInfo && !(authInfo instanceof AuthInfoModel)) {
      authInfo = BaseModel.createOrDefault(AuthInfoModel, authInfo);
    }

    this.rxLocalStorage.setItem(authInfoKey, authInfo);
    return authInfo as any;
  }

  updateAuthInfoByResponse(data: any): AuthInfoModel {
    const accessToken = data.accessToken;
    const jwtDecoded = jwt_decode<any>(accessToken);

    const authInfo = this.updateAuthInfo({
      nameUser: jwtDecoded.email,
      token: accessToken,
      expires: new Date(jwtDecoded.exp * 1000),
      id: jwtDecoded.userId,
      buyer: jwtDecoded.buyer,
      seller: jwtDecoded.seller,
    });

    return authInfo;
  }

  private handleAuthInfoOperator(): OperatorFunction<any, AuthInfoModel> {
    return map((data) => {
      const authInfo = this.updateAuthInfoByResponse(data);
      return authInfo;
    });
  }

  private filterIsAuthenticatedOperator() {
    return filter<AuthInfoModel>(
      (authInfo) => authInfo?.isAuthenticated() ?? false
    );
  }
}
