import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import isBase64 from 'is-base64';

enum IconType {
  Default = 0,
  Svg = 1,
  Image = 2,
}

@Component({
  selector: 'app-iconic',
  templateUrl: 'iconic.component.html',
  styleUrls: ['iconic.component.scss'],
})
export class IconicComponent implements OnChanges {
  @Input()
  icon: string;

  iconValue: string;

  iconTypes = IconType;
  iconType: IconType;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.icon && this.icon) {
      let icon = String(this.icon);
      const isSource =
        icon.startsWith('assets/') ||
        icon.startsWith('http://') ||
        icon.startsWith('https://');
      if (isSource) {
        if (icon.endsWith('.svg')) {
          this.iconType = IconType.Svg;
        } else {
          this.iconType = IconType.Image;
        }
      } else {
        const base64Bool = isBase64(icon, { allowMime: true });
        if (base64Bool) {
          this.iconType = IconType.Image;

          const hasMime = isBase64(icon, { mimeRequired: true });
          if (!hasMime) {
            icon = 'data:image/png;base64,' + icon;
          }
        } else {
          this.iconType = IconType.Default;
        }
      }

      this.iconValue = icon;
    }
  }
}
