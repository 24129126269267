import { BaseModel } from './base.model';

export interface AuthInfoLike {
  token?: string;
  nameUser?: string;
  expires?: Date;
  id?: string;
  buyer?: string;
  seller?: string;
}

export class AuthInfoModel
  extends BaseModel<AuthInfoLike, AuthInfoModel>
  implements AuthInfoLike
{
  token?: string;
  nameUser?: string;
  expires?: Date;
  id?: string;
  buyer?: string;
  seller?: string;

  get isBuyer() {
    return !!this.buyer;
  }

  get isSeller() {
    return !!this.seller;
  }

  apply(item: Partial<AuthInfoLike>) {
    this.token = item?.token;
    this.nameUser = item?.nameUser;
    this.expires = this.parseDateTime(item?.expires);
    this.id = item?.id;
    this.buyer = item?.buyer;
    this.seller = item?.seller;
  }

  isAuthenticated() {
    return !!(this.token && (this.expires?.getTime() ?? 0) > Date.now());
  }
}
