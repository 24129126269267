import { BaseModel } from './base.model';
import { BreedModelLike } from './breed.model';

export interface InstallmentModelLike {
  number?: number;
  price?: number;
}

export interface ImageItemLike {
  name?: string;
  url?: string;
}

export interface DocumentItemLike {
  name?: string;
  url?: string;
}

export interface LotBetItemLike {
  city?: string;
  dateBet?: Date;
  incremental?: number;
  price?: number;
}

export class LotBetItemModel
  extends BaseModel<LotBetItemLike, LotBetItemModel>
  implements LotBetItemLike
{
  city?: string;
  dateBet?: Date;
  incremental?: number;
  price?: number;

  apply(item: Partial<LotBetItemLike>) {
    this.city = item?.city;
    this.dateBet = this.parseDateTime(item?.dateBet);
    this.incremental = item?.incremental;
    this.price = item?.price;
  }
}

export interface LotPedigreeLike {
  father?: string;
  fatherFather?: string;
  fatherMother?: string;
  fatherFatherFather?: string;
  fatherFatherMother?: string;
  fatherMotherFather?: string;
  fatherMotherMother?: string;


  mother?: string;
  motherFather?: string;
  motherMother?: string;
  motherFatherFather?: string;
  motherFatherMother?: string;
  motherMotherFather?: string;
  motherMotherMother?: string;
}

export interface AuctionLotRunningDetailModelLike {
  id?: string;
  name?: string;
  resume?: string;
  description: string;
  lastParcela?: InstallmentModelLike;
  incrementMin?: number;
  dateEnd?: Date;
  whatsapp?: string;
  phone?: string;
  breed?: BreedModelLike;
  gender?: string;
  height?: number;
  birthday?: Date;
  studbook?: string;
  documents?: DocumentItemLike[];
  images?: ImageItemLike[];
  youtubeLinks?: any[];
  loteBets?: LotBetItemModel[];
  lotePedigree?: LotPedigreeLike;
  loteNumber?: number;
}

export class AuctionLotRunningDetailModel
  extends BaseModel<
    AuctionLotRunningDetailModelLike,
    AuctionLotRunningDetailModel
  >
  implements AuctionLotRunningDetailModelLike
{
  id?: string;
  name?: string;
  resume?: string;
  description: string;
  lastParcela?: InstallmentModelLike;
  incrementMin?: number;
  dateEnd?: Date;
  whatsapp?: string;
  phone?: string;
  breed?: BreedModelLike;
  gender?: string;
  height?: number;
  birthday?: Date;
  studbook?: string;
  documents?: DocumentItemLike[];
  images?: ImageItemLike[];
  youtubeLinks?: any[];
  loteBets?: LotBetItemModel[];
  lotePedigree?: LotPedigreeLike;
  loteNumber?: number;

  apply(item: Partial<AuctionLotRunningDetailModelLike>) {
    this.id = item?.id;
    this.name = item?.name;
    this.resume = item?.resume;
    this.description = item?.description;
    this.lastParcela = item?.lastParcela;
    this.incrementMin = item?.incrementMin;
    this.dateEnd = this.parseDateTime(item?.dateEnd);
    this.whatsapp = item?.whatsapp;
    this.phone = item?.phone;
    this.breed = item?.breed;
    this.gender = item?.gender;
    this.height = item?.height;
    this.birthday = this.parseDate(item?.birthday);
    this.studbook = item?.studbook;
    this.documents = item?.documents;
    this.images = item?.images;
    this.youtubeLinks = item?.youtubeLinks;
    this.loteBets = BaseModel.createArrayOrDefault(
      LotBetItemModel,
      item?.loteBets
    );
    this.lotePedigree = item?.lotePedigree;
    this.loteNumber = item?.loteNumber;
  }
}
